import React, { ChangeEvent } from "react";
import * as app from "../services/AppService";



interface Props { 
    OnChange: (...args:any) => any,
    Title: string,
    ID:string
}
export default function FileUpload(props: Props) {

    function readURL(event:ChangeEvent<HTMLInputElement>) {
        var input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (props.OnChange && typeof (props.OnChange) == "function")
                    //@ts-ignore
                    props.OnChange(e.target?.result?.split("base64,")[1], input.files?.[0].name)
            };
            reader.readAsDataURL(input.files[0]);
            const fileChosen = document.getElementById("fileChosen")!;
            fileChosen.textContent = input.files[0].name;
        }
    }

    return (
        <>
            <label className=" form-control" htmlFor={"file-upload-input" + props.Title}>
                {app.translate(props.Title)}
                <span className="iconssc-cloud ms-2"></span>
                <span id="fileChosen" className=""></span>
            </label>
            <input className="d-none"
                id={props.ID ? props.ID : "file-upload-input" + props.Title}
                type='file' onChange={readURL} accept=".xlsx,.xls" />
            
        </>
    );
}


FileUpload.defaultProps = {
    Title: "",
};
