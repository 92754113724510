/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { translate } from "../services/AppService";
import * as echarts from 'echarts';

export interface ChartData {
    name: string
    value: number
    data?: number[]
}
interface AreaChartProps {
    title: string,
    subTitle?: string,
    id?: string,
    height_px?: string,
    data: ChartData[],
    xAxisData: string[]
}

function AreaChart(props: AreaChartProps) {

    const [chart, setChart] = useState<any>({})

    useEffect(() => {

        var option: echarts.EChartsOption;

        var allNumbers = props.xAxisData ? props.data.map(r => r.data!).flat(1) : props.data.map(r => r.value);

        var max = Math.max(...allNumbers);
        var min = Math.min(...allNumbers);
        min = min - Math.abs(min - max) / 50

        option = {
            legend: {
                type: 'scroll',
                orient: 'vertical',
                left: 'right',
                data: props.data.map(r => r.name)
            },
            tooltip: {
                trigger: 'axis',
                //formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            title: {
                text: translate(props.title),
                subtext: translate(props.subTitle || ""),
                left: 'center'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: props.xAxisData ? props.xAxisData : props.data.map(r => r.name),
            },
            yAxis: {
                type: 'value',
                min,
                max
            },
            series: props.xAxisData ? props.data :
                {
                    data: props.data,
                    type: 'line'
                }
        };

        option && props.data.length > 0 && chart.setOption && chart.setOption(option, true, true);

    }, [props.data])

    useEffect(() => {
        var chartDom = document.getElementById(props.id || props.title || 'chart-container');
        setChart(echarts.init(chartDom, null, {
            renderer: 'canvas',
            useDirtyRect: false
        }));
    }, [])

    return (
        <div style={{ overflow: "hidden", position: "relative", margin: "20px", width: "1200px", height: props.height_px ? props.height_px + "px" : "300px" }}
            className={props.data.length > 0 ? "" : "d-none"}
            id={props.id || props.title || 'chart-container'}></div>
    );
}

export default AreaChart