import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import PropTypes from "prop-types";
import React, { useRef } from "react";
import * as app from "../services/AppService";

export default function ColorMode(props) {
    const menu = useRef(null);
    const items = [
        {
            template: () => <div className='p-2 pointer hover' onClick={() => setMode('light')}>  <span className="iconssc-light mx-1">&nbsp;</span><span>Light</span></div>,
        },
        {
            template: () => <div className='p-2 pointer hover' onClick={() => setMode('dark')}>  <span className="iconssc-dark mx-1">&nbsp;</span><span>Dark</span></div>,
        },
        {
            template: () => <div className='p-2 pointer hover' onClick={() => setMode('auto')}>  <span className="iconssc-auto mx-1">&nbsp;</span><span>Auto</span></div>,
        },
    ];

    function setMode(mode) {
        if (mode === "auto")
            app.setCookie("colorMode", window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
        else
            app.setCookie("colorMode", mode, 365);
        window.location.reload()
    }

    return <>
        <Menu model={items} popup ref={menu} baseZIndex={1500} />
        <Button className='shortcut btn-text-only nav-link '
            icon={`iconssc-${app.getCookie("colorMode", "auto")}`} onClick={(e) => menu.current.toggle(e)} />
    </>

}

ColorMode.propTypes = {
    TitleClass: PropTypes.string,
}

ColorMode.defaultProps = {
    TitleClass: "text-primary-emphasis fs-6"
}