import React, { ReactElement, ReactNode } from "react";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";


interface CheckboxProps extends RowItem{
    OnChange?: (checked: boolean,key:string|undefined) => any;
  Model: boolean;
  Disabled: boolean;
  Class: string;
  Label: string;
  Name: string;
  Key?: string;
  checkClass: string;
  IsTable: boolean;
  className:string
}

export default function Checkbox({ OnChange, Model, Disabled, Class, Label, Name, Key, checkClass, IsTable }:
    CheckboxProps): ReactElement {
    const id = Name || Label;

    function handler(event: { target: { checked: boolean } }) {
        const { checked } = event.target;
        if (OnChange && typeof OnChange == "function")
            OnChange(checked, Key);
    }

    let label = <label htmlFor={id} className={"ms-2 form-check-label text-primary-emphasis user-select-none c-pointer " + Class}>
        {app.translate(Label)}
    </label>;

        let input = (
          <input
            type="checkbox"
            name={Name}
            id={id}
            key={Key}
            disabled={Disabled}
            className="form-check-input"
            onChange={handler}
            checked={Model}
          />
        );

    let IsAr = app.getCookie("language", "en") == "ar";
    return (
        <div className={IsTable ? "" : `form-check ${checkClass}`}>
            {IsAr ? input : label}
            {IsAr ? label : input}
        </div>
    );
}

Checkbox.defaultProps = {
    Class: "",
    checkClass: "mt-1",
    Name: "",
    Label: "",
    IsTable: false,
    Disabled: false,
    className:''
};
