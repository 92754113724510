import React from "react";

export function MobileMode() {
    return (
      <div className="lock-browser  ">
        <div >
          <i className="fa-brands fa-chrome fa-2x mb-2"></i>
          <div>
            &nbsp;
            <p>لضمان أفضل تجربة مستخدم، يُوصى باستخدام متصفح جوجل كروم</p>
            <p>
              To ensure the best user experience, we recommend using Google
              Chrome.
            </p>
          </div>
        </div>
      </div>
    );
  }
 

