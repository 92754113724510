import React from 'react';
import * as app from "../services/AppService"

interface Props { 
    rowClass:string,
    rowKey:string, 
    colclass:string,  
    colsize:string,
    hide: boolean,
    children?: any,
    style?: object,
    colssize:string
}

export default function Row(props: Partial<Props> & { children: any }) {
    let Childs = ((Array.isArray(props.children) ? props.children : [props.children]).filter(component => typeof (component) == "object")).filter(c => !app.getBoolean(c.props.hide));
    return (app.getBoolean(props.hide) ? <></> :
        <div className={`row ${props.rowClass || ''}`} key={props.rowKey} style={props.style}>
            {
                Childs.map((child, index) =>
                    <div key={index} className={`col-lg${child.props && child.props.colsize ? "-" + child.props.colsize : props.colssize ? "-" + props.colssize : ""} col-md-${Childs.length !== 1 ? "6" : "12"} col-12 ${child.props && child.props.colclass ? child.props.colclass : props.colclass ? props.colclass : ''}`}>
                        {child}
                    </div>)
            }
        </div>
    );
}