import * as http from "../services/http"
import * as app from "../services/AppService"

export function logout() {
    return {
        type: "LOGOUT"
    }
}
export function reload(cb, IsLogout) {
    return (dispatch) => {
        let LangKey = localStorage.getItem("LangKey");
        http.get('/Login/GetLangKey', { IsLogout, WithOutTranslateKeys: LangKey !== null }, (payload) => {
            cb(payload);
            dispatch({
                type: "RELOAD",
                payload
            });
            if (!payload.IsLoged) {
                dispatch(logout);
            }
        })
    }
}
export function refreshToken(token) {
    return {
        type: "UPDATE_TOKEN",
        payload: token
    }
}
export function loginTrue(res, navigate, dispatch) {
    if (res.Code === "API") {
        app.showAlert(app.translate("MsgCNotLoginFromWebsite"))
        return 
    }
    dispatch({
        type: "LOGIN",
        payload: res.AdditionalData
    });
    var interfaceType = res.AdditionalData.InterfaceType;
    if ([1, 2, 3].includes(interfaceType))
        navigate('/UserDashboard');
    else if (interfaceType === 0)
        navigate('/AgentDashboard');
    else if (interfaceType === 4)
        navigate('/ClientDashboard');
    else if (interfaceType === 5)
        navigate('/ShippingClientDashboard');
    if (res.Message)
        app.showAlert(res.Message);
}
export function login(model, navigate, falseCb) {

    return (dispatch) => {
        http.post("/Login/LogIn", model, (res) => {
            if (res.Success)
                loginTrue(res, navigate, dispatch)
            else
                falseCb(res);
        });
    }
}

export default function appDataReducer(loginState = { isLoged: false, userType: -1, token: null }, action) {

    switch (action.type) {
        case "RELOAD":
            return {
                ...loginState,
                token: action.payload.IsLoged ? action.payload.User.Token : "",
                userType: action.payload.IsLoged ? action.payload.User.userType : -1
            };
        case "LOGIN":
            return {
                ...loginState,
                isLoged: true,
                token: action.payload.Token,
                userType: action.payload.userType
            };
        case "LOGOUT":
            return {
                ...loginState,
                token: null,
                userType: -1
            };
        case "UPDATE_TOKEN":
            return {
                ...loginState,
                isLoged: true,
                token: action.payload
            };
        default:
            return loginState
    }
}