/* eslint-disable react-hooks/exhaustive-deps */
import { AutoComplete as Auto, AutoComplete  } from "primereact/autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { KeyValue } from "src/models/key-value";
import * as app from "../services/AppService";
import "./Autocomplete.css";
import { AutocompleteProps } from "../models/autocomplete-props";


export default function Autocomplete(props: AutocompleteProps) {

    const [options, setOptions] = useState<KeyValue[]>([]);

    let initValue = {
        Key: "0",
        Value: props.DefaultValue || ""
    };


    const [value, setValue] = useState<any>(initValue);
    const optionsFilter = props.Filter || ((r: KeyValue, text: string) => (r.Value || '').toLowerCase().includes((text || '').toLowerCase()))

    const ref = useRef<AutoComplete>(null);

    useEffect(() => {
        //console.log("useEffect props.Options", props.Options)
        var item = !value || typeof (value) !== "object" ? { Key: "0", Value: props.DefaultValue||"" } : value;
        let selectedItem = props.Options && !(String(item.Key) === "0" && props.OnEnterClicked) ?
            props.Options.find((i: KeyValue) => String(i.Key) === String(item.Key)) : undefined;

        if (selectedItem)
            setValue(selectedItem);
        setOptions((+(props.Min || 0) > 0 && item.Value.length < +(props.Min || 0)) || !props.Options ? []
            : props.Options);//.filter((i: KeyValue) => optionsFilter(i, !value?"": value.Value || ""))
    }, [props.Options]);

    useEffect(() => {
        //console.log("useEffect props.DefaultValue", props.DefaultValue)
        setValue((old:any) => ({ ...old, Value: props.DefaultValue || "" }));
    }, [props.DefaultValue]);

    useEffect(() => {
        //console.log("useEffect props.Model", props.Model)
        let selectedItem = undefined;
        try {
            selectedItem = props.Options.find((item: KeyValue) => String(item.Key) === String(props.Model));
        } catch (e) { }
        if (selectedItem !== undefined) {
            selectedItemChanged(selectedItem)
        }
        else {
            selectedItemChanged({ Key: (props.Model===""?"":(props.Model || "0").toString()), Value: props.DefaultValue || "" })
        }
    }, [props.Model]);

    useEffect(() => {
        //console.log("useEffect options", options)
        if ((value && (+props.Model === 0 || String(value.Key) !== String(props.Model)) && document.activeElement?.id === (props.ID ? props.ID : "auto" + props.Label) && props.OnEnterClicked !== undefined) || props.OnSearch !== undefined)
            toggleDropdown(true);
    }, [options]);

    function selectedItemChanged(item: KeyValue) {
        //console.log("selectedItemChanged -> " + props.Label, item)
        toggleDropdown(false);
        setValue(item)
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(item.Key, item, props.Key, props.Options);
    }

    function onInputFocus(event: any) {
        //console.log("onInputFocus")
        setFocus(true)
        toggleDropdown(true);
        if (props.OnFocus && typeof props.OnFocus == "function") props.OnFocus();
    }

    function hanldeKeydown(evt: any) {
        //console.log("hanldeKeydown")
        if (evt.charCode === 13) {
            evt.preventDefault();
            setValue(initValue)
            if (props.OnEnterClicked && typeof props.OnEnterClicked == "function") { 
                if (typeof value === "string") {
                    props.OnEnterClicked(value);
                } else if (typeof value === "object") {
                    props.OnEnterClicked(value.Value);
                }
            }
        } else {
        }
    }

    function toggleDropdown(show: boolean) {
        //console.log("toggleDropdown", show)
        if (ref.current) {
            if (options?.length && show) {
                ref.current.show();
            } else if (options?.length)
                ref.current.hide();
        }
    }

    function search(Value: any) {
        if (typeof (Value) === "object") return;
        let text = Value !== undefined ? Value : props.DefaultValue;
        let item: KeyValue = { Value: text, Key: '0' };

        //console.log("onblur", item)
        setValue(item); 
        toggleDropdown(true);
        var options = +(props.Min || 0) > 0 && Value.length < +(props.Min || 0) ? [] : [
            ...props.Options.filter((i: KeyValue) => optionsFilter(i, Value || "")),
        ];
        setOptions(options);
        if (props.OnSearch && typeof (props.OnSearch) == "function")
            props.OnSearch(text);
        else if (options.length === 1 && options[0].Value === Value)
            selectedItemChanged(options[0])
    }

    function onblur() {
        var item = value && typeof (value) === "object" ? value : { Key: "0", Value: (value && typeof (value) === "string") ? value : (props.DefaultValue || "") }
        //console.log("onblur " + props.Label, item)
        if (props.OnBlur && document.activeElement?.id !== (props.ID ? props.ID : "auto" + props.Label)) {
            props.OnBlur?.(item);
        }
        var selected = props.Options.find((i: KeyValue) => String(i.Key) === String(item.Key))
        if (!selected && props.OnBlur === undefined && props.OnFocus === undefined && props.OnEnterClicked === undefined) {
            setValue("");
            selectedItemChanged({ Key: "", Value:"" })
        }
    }

    function ItemTemplate(item: KeyValue) {
        return (
            <div className={"flex align-items-center" + (+item.Key === 0 && props.showNewWord ? " bold" : "")}            >
                <div>
                    {" "}{+item.Key === 0 && props.showNewWord ? `( ${item.Value} )${app.translate("new")}` : item.Value}{" "}
                </div>
            </div>
        );
    }

    const [focus, setFocus] = useState<boolean | undefined>(undefined);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    useEffect(() => {
        clearTimeout(timer);
        setTimer(setTimeout(() => {
            if (focus === false)
                onblur()
        }, 300))
    }, [focus]);

    return (
        <div className={`auto-control  mt-1 ${props.containerClass || "mt-1"}`}>
            {props.Label && !props.IsTable && (
                <div className="d-flex justify-content-between ">
                    <label
                        className={`fw-semibold ${props.LabelClass || "text-primary-emphasis"
                            }`}
                    >
                        {app.translate(props.Label)}
                    </label>
                    {props.LabelElm !== undefined && (
                        <label className="fw-semibold text-danger me-2">
                            {props.LabelElm}
                        </label>
                    )}
                </div>
            )}

            <div className=" ctr border rounded bg-light-subtle">
                <div className=" w-100 input-group d-flex  flex-nowrap">
                    <Auto
                        ref={ref} 
                        field="Value"
                        delay={0}
                        minLength={+(props.Min || 0)}
                        key={props.Key}
                        inputId={props.ID ? props.ID : "auto" + props.Label}
                        disabled={props.Disabled}
                        onKeyPress={hanldeKeydown}
                        onSelect={e => selectedItemChanged(e.value)}
                        onFocus={onInputFocus}
                        completeMethod={(e) => search(e.query)}
                        onClick={() => {
                            //console.log("onClick", props.Options)
                            toggleDropdown(true);
                            setOptions(!value || !props.Options ||(+(props.Min || 0) > 0 && value.Value.length < +(props.Min || 0)) ? []
                                : props.Options);
                        }}
                        value={value}
                        suggestions={options}
                        onChange={(e) => {
                            setValue(e.value)
                            e.originalEvent?.preventDefault();
                        }}
                        onBlur={() => {
                            setFocus(false)
                            //console.log("SEX onBlur" + props.Label)
                        }}
                        className={`  border-0 rounded ${props.Required
                            ? " is-invalid  border-1"
                            : " border border-0 " + props.Class
                            }`}
                        required={props.Required}
                        itemTemplate={ItemTemplate} 
                    ></Auto>
                    {props.Icon && (
                        <button
                            className="btn btn-primary border-0 p-0 px-2"
                            type="button"
                            disabled={!props.IconClicked}
                            onClick={() => {
                                props.IconClicked?.(value);
                            }}
                        >
                            <i className={` iconssc-${props.Icon}`} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}