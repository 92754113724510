import React, {  ReactElement } from 'react'
import * as app from "../services/AppService";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TooltipElm from 'react-bootstrap/Tooltip';
import { RowItem } from '../models/row-item';

export interface ButtonProps extends RowItem{
    Key: string,
    Type?: "submit" | "reset" | "button" | undefined,
    Clicked: (key: string) => void,
    Rounded: string,
    Icon: string,
    ID: string,
    children: ReactElement | undefined,
    IconSize: string,
    hide: boolean,
    Tooltip: string,
    Disabled: boolean,
    Label: string,
    Class: string,
    IsTable?: boolean,
    formID?: string
}

export default function Button(props: ButtonProps) {

    function clicked(e: any) {
        if (props.Clicked) props.Clicked(props.Key)
        e.currentTarget.blur(); //الخروج من الزر بعد الضغط عليه
    }

    let btn = (<button type={props.Type} className={`btn btn-sm fw-normal focus-ring rounded-${props.Rounded} 
         ${props.Class} 
         ${(props.Icon !== "" && props.Rounded !== "circle" ? "  px-2 " : " ")}`}
        onClick={clicked}
        form={props.formID }
        id={props.ID ? props.ID : "btn" + props.Label}
        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" disabled={props.Disabled} >
        <span className={(props.Icon !== "" ? "" : " ")}>{app.translate(props.Label)}</span>

        {props.children}
        {props.Icon && <span className={`fs-${props.IconSize} iconssc-${props.Icon + (props.Label !== "" ? " ps-3" : " ")}`}> </span>}
    </button>);
    return (app.getBoolean(props.hide) ? <></> :
        !props.Tooltip ? btn :
            <OverlayTrigger key={props.Key + 'bottom'} placement='bottom'
                overlay={
                    <TooltipElm style={{ position: "fixed" }} id={`tooltip-bottom`}>
                        {app.translate(props.Tooltip)}
                    </TooltipElm>
                }>
                {btn}
            </OverlayTrigger>
    );
}

Button.defaultProps = {
    Key: "",
    Type: "button",
    Clicked: () => { },
    Rounded: "1",
    Icon: "",
    ID: "",
    IconSize: "6",
    hide: false,
    Tooltip: "",
    Disabled: false,
    Label: "",
    Class: "btn-primary shadow-sm ",
    children: undefined
}

