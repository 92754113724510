export function isChrome(): boolean {
  return (
    window.navigator.userAgent.toLocaleLowerCase().includes("crios") ||
    window.navigator.userAgent.toLocaleLowerCase().includes("chrome")
  );
}

export function isMobile() {
  return window.navigator.userAgent.toLocaleLowerCase().includes("mobile");
}


export function isNotChromeMobile(){
  return isMobile() && !isChrome()
}