/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as app from "../services/AppService";
import Input from "./Input";
import "./Table/Table.css";
import { KeyValue } from "../models/key-value";
import Select from "./Select";

interface TableProps {
    filterablCols?: string[]
    dataApi?: (pageNumber: number, pageSize: number) => void
    RowClicked?: (row: any, rowIndex: number) => void
    Cols: string[] | any[]
    Rows: string[] | any[]
    Data: any[]
    RowColor?: (row: any, rowIndex: number) => string
    RowClass?: (row: any, rowIndex: number) => string
    ContainerClass?: string
    responsive?: boolean;
    TheadTrClass?: string
    Class?: string
    ThBgColor?: string
    TableElements?: any[]
    ElementClass?: (name: string, row: any, ind: number) => string
    TableElementsDisable?: (name: string, row: any, ind: number) => boolean
    OnTableElmChanged?: (name: any, value: any, propName: string | undefined) => boolean
    Key?: string
    responsiveTemplate: any
    HideTotalCount?: boolean
    HidePagination?: boolean
}
class NavigatedPages {
    id: number = 1;
    data: any[] = [];
}
class Model {
    totalCount: number = 0;
    totalPages: number = 0;
    pageSize: number = 10;
    pageNumber: number = 1;
    hideNext: boolean = false;
    navigatedPages: NavigatedPages[] = [new NavigatedPages()];
    possiblePageSizes: KeyValue[] = [
        { Key: "10", Value: "10" },
        { Key: "50", Value: "50" },
        { Key: "100", Value: "100" },
        { Key: "1000", Value: "1000" },
    ];
    pageData: any[] = [];
    allTableData: any[] = [];
    desc: boolean = false;
    rearchInternal: boolean = false;
}
const Table = (props: TableProps) => {
    //---- State ----//
    let initNavigatedPages = { id: 1, data: [] };
    let isLivePaging = typeof props.dataApi === "function";

    const [model, setModel] = useState<Model>(new Model());

    const [filterModel, setFilterModel] = useState(props.filterablCols
            ? props.filterablCols.reduce((obj: any, currentProp) => {
                obj[currentProp] = "";
                return obj;
            }, {})
            : ""
    );

    function PageIndexHandle(pageNumber: number) {
        if (pageNumber === 0) return;
        if ((pageNumber <= model.totalPages && pageNumber > 0) || isLivePaging) {
            let isDatsExist = model.navigatedPages.map((r) => r.id).includes(pageNumber);

            if (!isDatsExist && isLivePaging && props.dataApi)
                props.dataApi(pageNumber - 1, model.pageSize);

            setModel((old) => {
                return {
                    ...old,
                    pageNumber,
                    allTableData: isDatsExist && isLivePaging ? old.navigatedPages[+pageNumber - 1].data : old.allTableData,
                    rearchInternal: !isDatsExist,
                    navigatedPages: isDatsExist ? old.navigatedPages : [...old.navigatedPages, { id: pageNumber, data: [] }],
                };
            });
        }
    }

    function PageSizeHandle(newPageSize: string) {
        if (isLivePaging && props.dataApi)
            props.dataApi(0, +newPageSize);
        setModel((old) => ({
            ...old,
            pageSize: Number(newPageSize),
            pageNumber: 1,
            rearchInternal: true,
            totalPages: Math.ceil(model.totalCount / +newPageSize),
            navigatedPages: [initNavigatedPages],
        }));
    }

    function RowClickedHandler(row: any, rowIndex: number) {
        if (props.RowClicked && typeof props.RowClicked == "function")
            props.RowClicked(row, rowIndex);
    }

    const applyFilters = (data: any[]) => {
        let filtered = [...data];
        for (let key in filterModel) {
            if (filterModel[key]) {
                var rowIndex = props.Cols.indexOf(key);
                filtered = filtered.filter((item) => item[props.Rows[rowIndex]] && String(item[props.Rows[rowIndex]]).toLowerCase().includes(filterModel[key].toLowerCase()));
            }
        }
        return filtered;
    };

    function UpdatePageData(allTableData: any[]) {
        let afterFilterData = applyFilters(allTableData);
        if (props.HidePagination || isLivePaging) {
            setModel((old) => ({ ...old, pageData: [...afterFilterData] }));
            return;
        }
        const pageData: any[] = [];
        let remainingCount =
            afterFilterData.length - (model.pageNumber - 1) * model.pageSize;
        let startIndex = (model.pageNumber - 1) * model.pageSize;

        let endIndex =
            remainingCount <= model.pageSize
                ? startIndex + remainingCount
                : startIndex + model.pageSize;

        for (var i = startIndex; i < endIndex; i++)
            pageData.push(afterFilterData[i]);
        setModel((old) => ({ ...old, pageData }));
    }
    function RowColor(row: any, rowIndex: number) {
        if (props.RowColor && typeof props.RowColor == "function")
            return props.RowColor(row, rowIndex);
    }
    function RowClass(row: any, rowIndex: number) {
        if (props.RowClass && typeof props.RowClass == "function") {
            const clas = props.RowClass(row, rowIndex);
            return "table-" + clas + " text-white ";
        }
    }
    //---- Use Effect to load data for first time  ----//

    React.useEffect(() => {
        let totalCount = props.Data.length;
        let totalPages = totalCount < 10 ? 1 : Math.ceil(totalCount / 10);
        setModel((old) => {
            let pageNumber = (isLivePaging && old.rearchInternal) || old.allTableData.length <= props.Data.length ? old.pageNumber : 1;
            let navigatedPages =
                isLivePaging && old.rearchInternal
                    ? old.navigatedPages
                    : [initNavigatedPages];
            if (isLivePaging) navigatedPages[pageNumber - 1].data = [...props.Data];
            return {
                ...old,
                allTableData: props.Data,
                totalCount,
                pageSize: (isLivePaging && old.rearchInternal) || old.allTableData.length <= props.Data.length ? old.pageSize : 10,
                rearchInternal: false,
                pageNumber,
                navigatedPages,
                totalPages,
            };
        });
    }, [props.Data]); //, props.Data.length

    //---- Use Effect to update data when changed  ----//
    React.useEffect(() => {
        UpdatePageData(model.allTableData);
    }, [model.pageNumber, model.pageSize, model.allTableData]);

    let rowCountElement = (
        <div className="col-auto mx-2 pt-1 ms-auto text-center bg-light-subtle border border-1 rounded ">
            <span className="fw-normal fw-bold px-2 d-flex align-items-center">
                <span className="d-none d-md-block">
                    {" "}
                    {app.translate("CountRecord")} :
                </span>
                <small className="">{props.Data && " " + props.Data.length}</small>
            </span>
        </div>
    );

    function order(index: number) {
        setModel((old) => {
            let allTableData = props.Data.sort((a, b) =>
                "" + a[props.Rows[index]] > "" + b[props.Rows[index]]
                    ? old.desc
                        ? 1
                        : -1
                    : old.desc
                        ? -1
                        : 1
            );
            UpdatePageData(allTableData);
            return { ...old, allTableData, desc: !old.desc };
        });
    }
    //---- RETURN ----//
    useEffect(() => {
        UpdatePageData(model.allTableData);
    }, [filterModel]);

    useEffect(() => setModel(old => ({
        ...old,
        hideNext: isLivePaging &&
            props.Data.length !== model.pageSize &&
            model.pageNumber ===
            model.navigatedPages[model.pageNumber - 1].id
    }))
        , [isLivePaging, props.Data.length, model.pageSize, model.pageNumber, model.navigatedPages]);
    return (
        <>
            <div
                className={
                    (props.ContainerClass === undefined ? "tableWrap-md " : props.ContainerClass) +
                    " overflow-auto " +
                    (props.responsive ? " d-none d-md-block " : "")
                }
            >
                <table
                    className={`table table-sm table-bordered border-dark-subtle align-middle table-hover m-0 ${props.Class}`}
                    aria-labelledby="tabelLabel"
                >
                    <thead className="position-sticky " style={{ cursor: "pointer" }}>
                        <tr className={(props.TheadTrClass !== undefined ? props.TheadTrClass:"text-light bg-primary-subtle ")}>
                            {props.Cols.filter(
                                (c: any, i: number) =>
                                    !(
                                        typeof c == "function" &&
                                        c(i).props &&
                                        app.getBoolean(c(i).props.hide)
                                    )
                            ).map((col, index) => {
                                let IsFilterable =
                                    props.filterablCols && props.filterablCols.includes(col);
                                return (
                                    <th
                                        key={index}
                                        scope="col-2"
                                        onClick={() => !IsFilterable && order(index)}
                                        className={
                                            (typeof props.Cols[index] == "function" || IsFilterable
                                                ? ""
                                                : "iconssc-down-arrow ") +
                                            (model.pageData.length > 0 &&
                                                typeof props.Rows[index] == "function" &&
                                                ((props.Rows[index](model.pageData[0]).type &&
                                                    props.Rows[index](model.pageData[0]).type.name ===
                                                    "Button") ||
                                                    (props.Rows[index](model.pageData[0]).props &&
                                                        props.Rows[index](model.pageData[0]).props.children &&
                                                        props.Rows[index](model.pageData[0]).props
                                                            .children[0] &&
                                                        props.Rows[index](model.pageData[0]).props.children[0]
                                                            .type &&
                                                        (props.Rows[index](model.pageData[0]).props
                                                            .children[0].type.name === "Button" ||
                                                            props.Rows[index](model.pageData[0]).props
                                                                .children[0].type.name === "DropdownButton")))
                                                ? `text-center position-sticky end-0 text-light  bg-${(props.ThBgColor || "primary")}-subtle `
                                                : `text-center text-light fw-bold bg-${(props.ThBgColor || "primary")}-subtle`)
                                        }
                                    >
                                        {typeof col == "function" ? (
                                            col(index)
                                        ) : IsFilterable ? (
                                            <Input
                                                Model={filterModel[col]}
                                                Placeholder={app.translate(col)}
                                                IsTable
                                                OnChange={(val) =>
                                                    setFilterModel((old: any) => ({ ...old, [col]: val }))
                                                }
                                            />
                                        ) : (
                                            app.translate(col)
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {model.pageData.length === 0 && (
                            <tr>
                                <td colSpan={100} className="table-active text-center fw-bold">
                                    {app.translate("EmptyTable")}
                                </td>
                            </tr>
                        )}
                        {model.pageData &&
                            model.pageData.map((row, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    className={
                                        "fw-lighter text-black rounded text-center " +
                                        RowClass(row, rowIndex)
                                    }
                                    onClick={() => RowClickedHandler(row, rowIndex)}
                                >
                                    {props.Rows &&
                                        props.Rows.filter(
                                            (r) =>
                                                !(
                                                    typeof r == "function" &&
                                                    r(row).props &&
                                                    app.getBoolean(r(row).props.hide)
                                                )
                                        ).map((name, Colindex) => {
                                            return typeof name == "function" &&
                                                name(row).type === "td" ? (
                                                name(row, Colindex + "|" + rowIndex)
                                            ) : (
                                                <td
                                                    key={Colindex + "|" + rowIndex}
                                                    style={{ background: RowColor(row, rowIndex) }}
                                                    className={
                                                        (typeof name == "function" &&
                                                            name(row).props &&
                                                            (name(row).props.Table ||
                                                                name(row).props.table === "true" ||
                                                                name(row).props.IsTable)) ||
                                                            (typeof name == "function" &&
                                                                name(row).props &&
                                                                name(row).props.children &&
                                                                name(row).props.children[0].props &&
                                                                name(row).props.children[0].props.Table)
                                                            ? " text-center position-sticky end-0 bg-body-tertiary  "
                                                            : typeof name == "function" &&
                                                                name(row).props &&
                                                                name(row).props.tableClass
                                                                ? name(row).props.tableClass
                                                                : " text-center fw-normal "
                                                    }
                                                >
                                                    {typeof name == "function"
                                                        ? name(row, rowIndex, Colindex)
                                                        : row[name]}
                                                </td>
                                            );
                                        })}

                                    {props.TableElements &&
                                        props.TableElements.map((name, ind) => (
                                            <td
                                                key={ind + "|" + rowIndex}
                                                className="text-center fw-normal "
                                            >
                                                <Input
                                                    IsTable
                                                    Model={row[name]}
                                                    Key={name}
                                                    TableClass={
                                                        props.ElementClass &&
                                                            typeof props.ElementClass == "function"
                                                            ? props.ElementClass(name, row, ind)
                                                            : props.ElementClass
                                                    }
                                                    Disabled={
                                                        props.TableElementsDisable &&
                                                            typeof props.TableElementsDisable == "function"
                                                            ? props.TableElementsDisable(name, row, ind)
                                                            : props.TableElementsDisable
                                                    }
                                                    OnChange={(value, propName) => {
                                                        props.OnTableElmChanged &&
                                                            props.OnTableElmChanged(
                                                                row[props.Key!],
                                                                value,
                                                                propName
                                                            );
                                                    }}
                                                />
                                            </td>
                                        ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div className="d-md-none ">
                {props.responsiveTemplate &&
                    model.pageData.map((r, i) => (
                        <props.responsiveTemplate key={i} {...r}></props.responsiveTemplate>
                    ))}
            </div>

            {!props.HidePagination && (
                //Pagination
                <div className="row g-0 my-2" style={{ cursor: "pointer" }}>
                    <div className="col col-lg-3">
                        {(+props.Data.length > model.pageSize || isLivePaging) && (
                            <nav aria-label="Page navigation">
                                <ul className="pagination pagination-sm m-0">
                                    <li className="page-item">
                                        <span className="page-link" onClick={() => PageIndexHandle(model.pageNumber - 1)} >
                                            {app.translate("Previous")}
                                        </span>
                                    </li>
                                    <>
                                        {isLivePaging ? (
                                            model.navigatedPages
                                                .filter((r) => r.id < model.pageNumber + 3 && r.id > model.pageNumber - 3)
                                                .map((r, i) => (
                                                    <li className="page-item" onClick={() => { PageIndexHandle(r.id); }} key={i} >
                                                        <span className={"page-link " + (r.id === model.pageNumber ? "active" : "")} >
                                                            {r.id}
                                                        </span>
                                                    </li>
                                                ))
                                        ) : (
                                            [-3, -2, -1, 0, 1, 2, 3].filter(r => r === 0 || (r > 0 && r + model.pageNumber <= model.totalPages) ||
                                                (r < 0 && model.pageNumber + r > 0)).map(i =>
                                                    <li className="page-item" onClick={() => PageIndexHandle(model.pageNumber + i)} >
                                                        <span className="page-link">
                                                            {model.pageNumber + i}
                                                        </span>
                                                    </li>)

                                        )}
                                    </>

                                    {!model.hideNext && (
                                        <li className="page-item">
                                            <span className="page-link" onClick={() => PageIndexHandle(model.pageNumber + 1)} >
                                                {app.translate("Next")}
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        )}
                    </div>
                    {!props.HideTotalCount && rowCountElement}
                    <div className={`col-auto  ${!props.HideTotalCount ? null : " ms-auto "} `} >
                        <Select
                            ID="pageSize"
                            containerClass="mt-0"
                            Options={model.possiblePageSizes}
                            Model={model.pageSize}
                            OnChange={PageSizeHandle}
                        />
                    </div>
                </div>
            )}
            {!props.HideTotalCount && props.HidePagination && (
                <div className="row g-0 my-2">{rowCountElement}</div>
            )}
        </>
    );
};

Table.propTypes = {
    Cols: PropTypes.array.isRequired,
    Rows: PropTypes.array.isRequired,
    Data: PropTypes.array.isRequired,
    Key: PropTypes.string,
    Class: PropTypes.string,
    //Buttons:
    TableElements: PropTypes.array,
    OnTableElmChanged: PropTypes.func,
    TheadTrClass: PropTypes.string,
    ThBgColor: PropTypes.string,
    ElementClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    RowClicked: PropTypes.func,
    HideTotalCount: PropTypes.bool,
    HidePagination: PropTypes.bool,
    RowColor: PropTypes.func,
    TableElementsDisable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    responsive: PropTypes.bool,
    responsiveTemplate: PropTypes.any,
};
export default Table;
