import React, { useEffect, useState } from "react";
import { CardModel } from "../models/card-model";
import { ResultVM } from "../models/result-vm";
import { RowItem } from "../models/row-item";
import * as app from "../services/AppService";
import * as card from "../services/CardService";
import * as http from "../services/http";
import Autocomplete from "./Autocomplete";
import Button from "./Button";
import Dialog from "./PublicDialogs/Dialog";
import Table from "./Table";

interface CardSearchProps extends RowItem {
  ID?: string;
  Label?: string;
  Model: CardModel;
  Filter?: string;
  OnChange: (args: any) => any;
  AfterSearch?: (args: any) => any;
  ShowTable?: boolean;
  Disabled?: boolean;
  Required?: boolean;
  OnFocus?: () => any;
  WithOutSearch?: boolean;
  searchOnBlur?: boolean;
  Icon?: string;
  IconClicked?: (...args: any) => any;
  Placeholder?: string;
}

interface SearchDataModel {
  Model: any;
  autoOptions: any[];
  tableOptions: any[];
  ShowSimilarDialog: boolean;
  DefaultString: string;
}

function CardSearch(props: CardSearchProps) {
    let options = app.options();
    const isAgent = +app.options().InterfaceType === 0;

    const [searchData, setSearchData] = useState<SearchDataModel>({
        Model: props.Model.ID,
        autoOptions: [],
        tableOptions: [],
        ShowSimilarDialog: false,
        DefaultString: "",
    });

    useEffect(() => {
        setSearchData((old) => ({
            ...old,
            Model: props.Model.ID,
            DefaultString: props.Model.FirstName,
        }));
        if (+props.Model.ID === 0 && props.Model.FirstName === "")
            setSearchData((old) => ({ ...old, autoOptions: [], tableOptions: [] }));
    }, [props.Model.FirstName, props.Model.ID]);

    function getModel(Name: string = "") {
        return { ...card.GetDefault(Name), Card_Jop: props.Model.Card_Jop };
    }

    function getCards(Name: string) {
        if (Name.length < 3) return;
        setSearchData((old) => ({
            ...old,
            DefaultString: "",
        }));
        http.get(
            "/Service/SearchCards",
            { Name, Filter: props.Filter },
            (res: ResultVM) => {
                var Cards = res.AdditionalData;
                setSearchData((old) => ({
                    ...old,
                    DefaultString: "",
                    autoOptions:
                        props.AfterSearch || props.WithOutSearch
                            ? Cards
                            : [
                                {
                                    Value: Name,
                                    Key: 0,
                                    Name,
                                },
                                ...Cards,
                            ],
                    tableOptions: Cards ? Cards : [],
                    ShowSimilarDialog:
                        +options.IsCentralBank === 1 && props.ShowTable === true,
                }));

                if (Cards.length === 0) {
                    if (props.WithOutSearch) {
                        props.OnChange && props.OnChange(0);
                        return;
                    }
                    if (props.searchOnBlur) {
                        props.OnChange(getModel(Name));
                        return;
                    }
                    if (props.AfterSearch && typeof props.AfterSearch == "function")
                        props.AfterSearch(getModel(Name));
                    else props.OnChange(getModel(Name));
                } else {
                    setSearchData((old) => ({ ...old, DefaultString: "" }));
                    if (props.searchOnBlur) {
                        var elm = document.getElementById(props.ID ? props.ID : "auto" + props.Label);
                        if (elm) {
                            elm.focus()
                        }
                    }
                }
            }
        );
    }

    function getCardByID(CardID: string, Obj: any) {
        if (props.WithOutSearch) {
            props.OnChange && props.OnChange(CardID);
            return;
        }
        if (+CardID === 0) {
            const DefaultString = Obj.Name ? Obj.Name : Obj.Value;
            props.OnChange(getModel(DefaultString));
            return;
        }
        if (+props.Model.ID === +CardID || CardID === undefined) return; //, WithImage: false
        http.get(
            "/Service/GetCardByID",
            { CardID, WithImage: +(props.Filter ? props.Filter : 2) === 2 },
            (res: ResultVM) => {
                if (res.Success) {
                    props.OnChange(res.AdditionalData);
                    setSearchData((old) => ({
                        ...old,
                        autoOptions: [Obj],
                        tableOptions: [],
                    }));
                }
                if (!res.CarryOnData) app.disblayError(res, true);
            }
        );
    }

    function onBlur(item: any) {
        if (
            props.searchOnBlur &&
            (!props.Model.FirstName ||
                props.Model.FirstName.trim() !== item.Value.trim())
        ) {
            setSearchData(old => ({ ...old, DefaultString: item.Value }))
            setTimeout(() => getCards(item.Value), 100);
        }
        if (item.Value.trim() === "" && +props.Model.ID > 0)
            props.OnChange(getModel());
    }

    function enterClicked(name: string) {

        getCards(name);
    }

    function rowClicked(item: { Key: string }) {
        setSearchData((old) => ({ ...old, ShowSimilarDialog: false }));
        getCardByID(item.Key, item);
    }
    return (
        <>
            <Autocomplete
                Options={searchData.autoOptions}
                DefaultValue={searchData.DefaultString}
                Model={searchData.Model}
                Label={props.Label}
                Disabled={props.Disabled}
                Required={props.Required}
                OnChange={getCardByID}
                OnEnterClicked={enterClicked}
                OnFocus={props.OnFocus}
                Icon={props.Icon || "search"}
                IconClicked={(item) => {
                    if (props.IconClicked)
                        props.IconClicked()
                    else
                        enterClicked(item.Value);
                }}
                OnBlur={onBlur}
                Placeholder={props.Placeholder}
                ID={props.ID}
                showNewWord={isAgent}
            />
            <Dialog
                Show={searchData.ShowSimilarDialog}
                Title="FrmSimilarOperat"
                Size="lg"
                OnClose={() => {
                    setSearchData((old) => ({ ...old, ShowSimilarDialog: false }));
                }}
            >
                <Table
                    Data={searchData.tableOptions}
                    HidePagination
                    HideTotalCount
                    ContainerClass=" tableWrap-sm"
                    RowClicked={rowClicked}
                    Cols={["BeneficiaryName", "Phone", "Address", "Select"]}
                    Rows={[
                        "Value",
                        "Additional",
                        "AdditionalInfo",
                        (item: any) => (
                            <Button
                                IsTable
                                Label="Select"
                                Clicked={(e) => rowClicked(item)}
                            />
                        ),
                    ]}
                />
            </Dialog>
        </>
    );
}

CardSearch.defaultProps = {
  Filter: 2,
  Disabled: false,
  Required: false,
};
export default CardSearch;
