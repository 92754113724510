import React, { ChangeEvent } from "react";
import * as app from "../services/AppService";
import Button from "../components/Button";
import { KeyValue } from "../models/key-value";
import { RowItem } from "../models/row-item";
export interface SelectProps extends RowItem {
    OnChange?: (selected: string, arr: KeyValue[] | any[], SelectID?: string) => void,
    Model: string | number,
    Required?: boolean,
    Disabled?: boolean,
    Class?: string,
    Label?: string,
    selectdObjectChange?: (selected:any)=>void,
    Options: KeyValue[] | any,
    ID?: string,
    IsTable?: boolean,
    Icon?: string,
    Clicked?: () => void,
    containerClass?: string,
    LabelClass?: string,
    Key?: string,
    Value?: any,
    OnBlur?: (newValue: string) => void,
    colsize?: string;
} 

function Select(props: SelectProps) {

    function handler(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;
        if (props.selectdObjectChange) {
            var selected = props.Options.find((r: any) => r[props.Key!] + '' === value + '');
            props.selectdObjectChange(selected);
        }
        else if (props.OnChange && typeof props.OnChange == "function")            
                props.OnChange(value, props.Options, props.ID);
    }
    function OnBlurhandler(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;
        if (props.OnBlur && typeof (props.OnBlur) === "function")
            props.OnBlur(value);
    }

    let select = (<select
        className={`form-select form-select-sm rounded ${props.Required && !props.Model ? " is-invalid  border-1" : " border border-0 " + props.Class}`}
        disabled={props.Disabled}
        required={props.Required && !props.Model}
        onChange={handler}
        onBlur={OnBlurhandler}
        id={props.ID ? props.ID : "slct" + props.Label}
        value={props.Model}>

        {props.Options && props.Options.find((item: any)   => +item[props.Key!] === 0 || item[props.Key!] + '' !== props.Model + '') === undefined && (
        <option defaultValue="0" disabled={true} value="0"></option>
        )}
        {props.Options && props.Options.find((item: any) => item[props.Key!] + '' === props.Model + '') === undefined && (
            <option defaultValue={props.Model} disabled={true} value={props.Model}></option>
        )}

        {(props.Options || []).map((item: any) => {
            return (
                <option key={item[props.Key!]} value={item[props.Key!]}>
                    {typeof (props.Value) == "function" ? props.Value(item) : item[props.Value||'']}
                </option>
            );
        })}
    </select>)
    return (props.IsTable ? select :
        <div className={props.containerClass}>
                        {props.Label && (
                <label className={props.LabelClass}>{app.translate(props.Label)}</label>
            )}
            <div className={`border rounded bg-light-subtle input-group `} >
                {select }
                {props.Icon &&
                    <Button
                    Icon={props.Icon}                                                
                    Class="text-primary rounded-end rounded-1  "
                    Clicked={props.Clicked} />
                }
            </div>
        </div>

    );
}

Select.defaultProps = {
    Class: " ",
    Key: "Key",
    Value: "Value",
    LabelClass: "fw-semibold text-primary-emphasis ",
    Disabled: false,
    Required: false,
    containerClass: " mt-1",
    IsTable: false,
    Label:""
};

export default Select;
