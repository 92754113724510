import React, { ChangeEvent, KeyboardEvent } from "react";
import { RowItem } from "../models/row-item";
import * as app from "../services/AppService";

interface InputProps extends RowItem {
  OnChange?: (value: string, key: string | undefined) => void;
  Model: string | number;
  Required?: boolean;
  Disabled?: boolean;
  OnBlur?: () => void;
  Class?: string;
  Label?: string;
  Key?: string;
  OnEnterClicked?: () => void;
  IconClicked?: () => void;
  ID?: string;
  IsTable?: boolean;
  Maxlength?: number;
  LabelClass?: string;
  ContainerClass?: string;
  Symbol?: string;
  Placeholder?: string;
  WithLabel?: boolean;
  Icon?: string;
  IconClass?: string;
  TableClass?: string;
  autoComplete?: "on" | "off";
}

function Input(props: InputProps) {
  function handler(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (props.OnChange && typeof props.OnChange == "function")
      props.OnChange(value, props.Key);
  }

  function hanldeKeydown(evt: KeyboardEvent<HTMLInputElement>): void {
    if (
      evt.key === "Enter" &&
      props.OnEnterClicked &&
      typeof props.OnEnterClicked == "function"
    )
      props.OnEnterClicked();
  }

  function iconClicked() {
    if (props.IconClicked && typeof props.IconClicked == "function")
      props.IconClicked();
  }

  function inputBlur() {
    if (props.OnBlur && typeof props.OnBlur == "function") props.OnBlur();
  }

  let input = (
    <input
      type="text"
      maxLength={props.Maxlength}
      id={props.ID ? props.ID : "inpt" + props.Label}
      key={props.Key}
      className={`form-control form-control-sm rounded ${
        props.Required && !props.Model
          ? " is-invalid  border-1"
          : props.IsTable
          ? " border-1 text-center bg-body-tertiary " + props.TableClass
          : "border-0 " + props.Class
      }`}
      disabled={props.Disabled}
      onChange={handler}
      value={props.Model}
      onBlur={inputBlur}
      onKeyDown={hanldeKeydown}
      placeholder={app.translate(props.Placeholder) + props.Symbol}
      required={props.Required && !props.Model}
      autoComplete={props.autoComplete || null!}
    />
  );

  return props.IsTable ? (
    input
  ) : (
    <div className="mt-1">
      {props.WithLabel && (
        <label className={`${props.LabelClass}`}>
          {app.translate(props.Label)}
        </label>
      )}
      <div className={`input-group border rounded  ${props.ContainerClass}`}>
        {input}
        {props.Icon && (
          <button
            className={`btn btn-sm border-0 p-0 px-2 ${props.IconClass}`}
            type="button"
            disabled={!props.IconClicked}
            onClick={iconClicked}
          >
            <i className={` iconssc-${props.Icon}`} />
          </button>
        )}
      </div>
    </div>
  );
}

Input.defaultProps = {
  LabelClass: "fw-semibold text-primary-emphasis",
  Type: "text",
  Disabled: false,
  Required: false,
  Key: "input",
  IsTable: false,
  WithLabel: true,
  Symbol: "",
  OnEnterClicked: undefined,
  IconClicked: undefined,
  Maxlength: undefined,
  ContainerClass: "",
  Placeholder: "",
  Icon: "",
  IconClass: "",
  TableClass: "",
  Class: "",
};

export default Input;
