import React, { ChangeEvent } from 'react';
import * as app from "../services/AppService";
import { RowItem } from '../models/row-item';

interface Props extends RowItem{
  ID?: string;
  Label?: string;
  Class?: string;
  Disabled?: boolean;
  Type?: string;
  Model: string | number;
  Placeholder?: string;
  Key?: string;
  IsTable?: boolean;
  minValue?: number;
  maxValue?: number;
  TableClass?: string;
  LabelClass?: string;
  ContainerClass?: string;
  Icon?: string;
  OnChange?: (...args: any) => void;
  OnBlur?: (...args: any) => void;
}

export default function Number(props : Props) {

  function handler(event : ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (props.OnChange && typeof props.OnChange == "function") props.OnChange(value, props.Key);
  }
  let input = (
    <input
      type="number"
      id={props.ID ? props.ID : "num" + props.Label}
      key={props.Key}
      className={`form-control form-control-sm border rounded ${
        props.IsTable ? " border-1  text-center " + props.TableClass : props.Class
      }`}
      disabled={props.Disabled}
      onChange={handler}
      value={props.Model}
      onBlur={props.OnBlur}
      placeholder={app.translate(props.Placeholder)}
      min={props.minValue}
      max={props.maxValue}
    />
  );
  return props.IsTable ? (
    input
  ) : (
    <div className="mt-1">
      <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>
      <div className={`input-group rounded  ${props.ContainerClass}`}>
        {input}
        {props.Icon && (
          <button className="btn btn-light" type="button" disabled>
            <i className={"text-primary-emphasis " + `icofont-${props.Icon}`} />
          </button>
        )}
      </div>
    </div>
  );
}

Number.defaultProps = {
    Class: " ",
    Placeholder: "",
    LabelClass: " fw-semibold text-primary-emphasis ",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    minValue: 0
}


