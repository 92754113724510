import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import PropTypes from "prop-types";
import React, { useRef } from "react";
import * as app from "../services/AppService";
import * as http from "../services/http";

export default function Language(props) {
    const menu = useRef(null);
    const items = [
        {
            template: () => <div className='p-2 pointer hover' onClick={() => changeLang('ar')}>
                <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206719.png" alt="" />
                <span className="mx-1">العربية</span>
            </div>,
        },
        {
            template: () => <div className='p-2 pointer hover' onClick={() => changeLang('en')}>
                <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206626.png" alt="" />
                <span className="mx-1">English</span>
            </div>,
        },
        {
            template: () => <div className='p-2 pointer hover' onClick={() => changeLang('tr')}>
                <img className="mx-3" src="https://cdn-icons-png.flaticon.com/32/206/206634.png" alt="" />
                <span>Turkish</span>
            </div>,
        },

    ]


    async function changeLang(lang) {
        http.get(`/LogIn/SetLang?lang=${lang}`, {}, () => {
            app.setCookie("language", lang, 365);
            localStorage.clear("LangKey")
            window.location.reload()
        });
    }

    return <>        <Menu model={items} popup ref={menu} baseZIndex={1500} />
        <Button className='shortcut btn-text-only nav-link'
            icon={`iconssc-language`} onClick={(e) => menu.current.toggle(e)} />
    </>

}

Language.propTypes = {
    Class: PropTypes.string,
    TitleClass: PropTypes.string,
}

Language.defaultProps = {
    Class: "mt-1 fs-6 ",
    TitleClass: "text-primary-emphasis "
}
