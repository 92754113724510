/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { translate } from "../services/AppService"; 
import * as echarts from 'echarts';

export interface ChartData {
    name: string
    value: number
}
interface PieChartProps {
    title: string,
    tooltipName: string,
    subTitle?: string,
    id?: string,
    height_px?: string,
    data: ChartData[]
}

function PieChart(props: PieChartProps) {
    type EChartsOption = echarts.EChartsOption;

    useEffect(() => {
        var chartDom = document.getElementById(props.id || props.title || 'chart-container');
        var myChart = echarts.init(chartDom, null, {
            renderer: 'canvas',
            useDirtyRect: false
        });
        var option: EChartsOption; 

        option = {
            title: {
                text: translate(props.title),
                subtext: translate(props.subTitle || ""),
                left: 'right'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                    name: translate(props.tooltipName),
                    type: 'pie',
                    radius: '85%',
                    center: ['50%', '50%'],
                    //roseType: 'area',
                    data: props.data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        option && props.data.length > 0 && myChart.setOption(option);

    }, [props.data])

    return (
        <div style={{ overflow: "hidden", position: "relative", margin: "20px", width: "1000px", height: props.height_px ? props.height_px + "px" : "300px" }}
            className={props.data.length > 0?"":"d-none" }
            id={props.id || props.title || 'chart-container'}></div>
    );
} 

export default PieChart