import store from "../store";
import { logout, refreshToken } from "../store/auth";
import * as app from "./AppService";

var baseUrl = ""; //  "https://app.salahsoft.net";

var IsUpdatingToken = false;
export var LoadingTime = 0;
function hideLoading(withOutSpinner) {
  if (!withOutSpinner && LoadingTime-- === 1) app.HideLoading();
}
export function BaseUrl() {
  return baseUrl;
}

function GetAuthHeader(token) {
  return token
    ? {
        //"Access-Control-Allow-Origin": "https://localhost:44430",
        "Content-Type": "application/json; charset=UTF-8",
        //"Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      }
    : {
        //"Access-Control-Allow-Origin": "https://localhost:44430",//TEMP
        "Content-Type": "application/json; charset=UTF-8",
        //"Access-Control-Allow-Credentials": true
      };
}

export function get(
  url,
  headerObject,
  OkCb,
  withOutSpinner,
  IsAfterUpdateToken
) {
  if (!headerObject) headerObject = {};
  const token = store.getState().loginState.token;
  const parameters = Object.keys(headerObject)
    .map(function (key) {
      return (
        key +
        "=" +
        (Array.isArray(headerObject[key])
          ? headerObject[key].toString()
          : typeof headerObject[key] === "object"
          ? JSON.stringify(headerObject[key])
          : headerObject[key])
      );
    })
    .join("&");
  let Params = "";
  if (parameters && parameters.length > 0) {
    Params += "?" + parameters;
  }
  if (!withOutSpinner) {
    LoadingTime++;
    app.ShowLoading();
  }
  const controller = new AbortController();
  setTimeout(() => {
    //hideLoading(withOutSpinner)
    //controller.abort();
  }, 59000);
  fetch(baseUrl + url + Params, {
    signal: controller.signal,
    method: "get",
    credentials: "include",
    headers: GetAuthHeader(token),
  })
    .then((response) => {
      hideLoading(withOutSpinner);
      if (response.ok) return response.json();
      throw new Error(
        response.status + " : " + response.statusText + "( " + url + " )"
      );
    })
    .then((data) => {
      if (OkCb && typeof OkCb == "function") OkCb(data);
    })
    .catch((errorMsg) => {
      if (
        errorMsg.message.length > 3 &&
        errorMsg.message.substring(0, 3) === "401" &&
        !IsAfterUpdateToken
      )
        updateToken(url, headerObject, OkCb, "get", withOutSpinner);
      else app.showAlert(errorMsg.message);
    });
}

export function post(url, body, OkCb, IsAfterUpdateToken, withOutSpinner) {
  const token = store.getState().loginState.token;
  if (!withOutSpinner) {
    LoadingTime += 1;
    app.ShowLoading();
  }
  const controller = new AbortController();
  setTimeout(() => {
    //hideLoading(withOutSpinner)
    //controller.abort();
  }, 59000);
  fetch(baseUrl + url, {
    signal: controller.signal,
    method: "post",
    credentials: "include",
    headers: GetAuthHeader(token),
    body: JSON.stringify(body),
  })
    .then((response) => {
      hideLoading(withOutSpinner);
      if (response.ok) return response.json();
      throw new Error(
        response.status + " : " + response.statusText + "( " + url + " )"
      );
    })
    .then((data) => {
      if (OkCb) OkCb(data);
    })
    .catch((errorMsg) => {
      if (
        errorMsg.message.length > 3 &&
        errorMsg.message.substring(0, 3) === "401" &&
        !IsAfterUpdateToken &&
        url !== "/Login/RefreshToken"
      )
        updateToken(url, body, OkCb, "post", withOutSpinner);
      else app.showAlert(errorMsg.message);
    });
}

export function updateToken(url, body, OkCb, type, withOutSpinner) {
  if (IsUpdatingToken) {
    setTimeout(() => {
      if (type === "get") get(url, body, OkCb, withOutSpinner, false);
      else post(url, body, OkCb, false, withOutSpinner);
    }, 1000);
    return;
  }
  IsUpdatingToken = true;

  post(
    "/Login/RefreshToken",
    "",
    (res) => {
      IsUpdatingToken = false;
      if (res.Success) {
        store.dispatch(refreshToken(res.AdditionalData));
        if (type === "get") get(url, body, OkCb, withOutSpinner, true);
        else post(url, body, OkCb, true, withOutSpinner);
      } else {
        store.dispatch(logout);
        window.open("/Signin", "_self");
      }
    },
    true,
    true
  );
}
