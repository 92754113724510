import React, { ChangeEvent } from "react";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";

interface Props extends RowItem {
    OnChange?: (res:boolean) => void,
    OnBlur?: () => void,
    Address?: string,
    ContainerClass?: string,
    ID?: string,
    Label?: string,
    Disabled?: boolean,
    Value: string | boolean,
    Class?: string
}

function RadioBox(props: Props) {
    function handler(event: ChangeEvent<HTMLInputElement>) {
        const { checked } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(checked);
    }
    function blurHandler() {
        if (props.OnBlur && typeof props.OnBlur == "function")
            props.OnBlur();
    }

    return (
        <>
            {app.translate(props.Address) && <p className="text-primary-emphasis fw-semibold m-1">{app.translate(props.Address)}</p>}

            <div className={props.ContainerClass}>
                <input
                    id={props.ID ? props.ID : "radio" + props.Label}
                    key={"radio-key" + props.ID ? props.ID : props.Label}
                    type="radio"
                    name={"radio-name" + props.ID ? props.ID : props.Label}
                    disabled={props.Disabled}
                    className="form-check-input ms-1"
                    onChange={handler}
                    value={props.Value.toString()}
                    checked={app.getBoolean(props.Value)}
                    onBlur={blurHandler}
                />
                <label htmlFor={"radio-name" + props.ID ? props.ID : props.Label} className={"ms-2 " + props.Class}>
                    {app.translate(props.Label)}
                </label>
            </div>
        </>
    );
}

RadioBox.defaultProps = {
    Class: "fw-semibold text-primary-emphasis ",
    ContainerClass: "",
    Name: "chk",
    Label: "",
    Disabled: false,
};

export default RadioBox;