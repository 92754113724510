import React from 'react';
import './UserNavBar.css';
import Button from 'react-bootstrap/Button';


export default function IconNavBar(props) {

    return (
        <nav id="sidebarMenu" className="icon-sidebar bg-primary-subtle">
            <div className="position-sticky pt-2">

                <ul className="nav p-2">
                    <li className="nav-item mb-4">
                        <Button variant="secondary" className="text-light fs-5 rounded" onClick={() => window.open(window.location.origin + "/UserDashboard", "_self") }><span className="iconssc-home"></span></Button>
                    </li>

                </ul>
            </div>
        </nav>
    );
}