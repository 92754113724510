import React from "react";
import { RowItem } from "../models/row-item";
interface SpanProps extends RowItem {
    children?: any
    Class?: string
    Table?: boolean
}
export default function Span(props: SpanProps) {//من اجل خاصية اخفاء عمود من الجدول ما تعطي مشكلة 
    return (
        <span className={props.Class }>{props.children}</span>
    );
}
