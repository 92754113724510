import React, { ReactNode, ReactElement } from 'react';
import * as app from "../services/AppService";

function AccordionItem({ children, Title, Disabled, Show }: { children: ReactElement, Title: string, Disabled: boolean, Show: boolean }): ReactNode {
    return (

        <div className="accordion-item ">
            <h2 className="accordion-header border-bottom" id="headingOne">
                <button style={{ "height": "35px" }} className={`accordion-button color-danger fw-bold ${Disabled ? "opacity-50" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${Title}`} aria-expanded="true" aria-controls="collapseOne" disabled={Disabled}>
                    {app.translate(Title)}
                </button>
            </h2>
            <div id={Title} className={`accordion-collapse collapse  ${Show ? "show" : ""}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    {children}
                </div>
            </div>
        </div>

    );
}

AccordionItem.defaultProps = {
    Class: " ",
    Disabled: false,
    Show: false
}

export default AccordionItem;