import * as http from "../services/http";
import { userRoutes, agentRouts, clientRouts, shippingClientRouts } from "../routes/RouteList";

const initialState = {
    token: "",
    options: {
        NumbersAfterDecimal: 2,
        RoundType: 2,
        CurrList: []
    },
    perms: [],
    routs: [],
    userType: -1,
    LangKey: [],
    Branches: [],
    UserBranches: [],
    AgentsCountries: [],
    BanksCountries: [],
    AgentCities: [],
    Cities: [],
    AllCities: [],
    Agents: [],
    AgentCurrencies: [],
    Currencies: [],
    Nationalities: [],
    IdentityKind: [],
    permissions: [],
    LicenseKinds: [],
    layoutVal: 0,
    AgentCur: [],
    branchCurrencies: [],
    Tellers: [],
    AllUserTellers: [],
    Countries: [],
    BranchTellers: [],
    CurrentBranchCurr: [],
    AllBranchesCurrencies: [],
    Banks: [],
    RemBanks: [],
    RoundOption: null,
    IsCentralBank: null,
    UserTellers: [],
    Warehouses: [],
    Companies: [],
    TranCompanies: [],
    Containers: [],
    ExchReasons: [],
    RemReasons: [],
    AllUsers: [],
    ResourcesLang: [],
    AgentsAccounts: [],
    CostCenters: [],
    BankBranches: [],
    Accounts: [],
    Jobs: [],
    AllCurrencies: [],
    AgentBranches: [],
    AgentBalance: { list: [], balanceAccount: "0" },
};
var Loading = [];

export const getData = (dataName, headerObj, cb) => async (dispatch, getState) => {
    if (Loading.includes(dataName)) 
        await new Promise(r => setTimeout(r, 2000));
    const data = getState().data[dataName];
    if (data && data.length > 0) { if (cb) cb(data); return; }
    Loading.push(dataName)
    http.get('/Service/Get' + dataName, headerObj, (data) => {
        Loading = Loading.filter(r => r !== dataName)
        dispatch({
            type: "ADD_DATA",
            dataName,
            payload: data.AdditionalData
        });
        setTimeout(() => { if (cb) cb(data.AdditionalData) }, 0);
    });
};

function getAvailableRouts(perms, options) {
    var InterfaceRoutes = options.InterfaceType == 0 ? agentRouts : options.InterfaceType == 4 ? clientRouts : options.InterfaceType == 5 ? shippingClientRouts : userRoutes;
    return InterfaceRoutes.filter(item => {
        if (
            (item.perm !== 31 || options.UserID === 1) &&//عدم اظهار الحسابات المجمدة غير لليوزر الادمن
            ((item.perm === 0 && !item.perms) ||//اظهار النوافذ التي لاتحتاج صلاحيات
                perms.find(row => { return (row.PermName == item.perm || (row.PermName === 7202 && item.perm === 7200)) && row[item.permKind ? item.permKind : "Read"] == 1 }) ||//اظهار النوافذ التي يملك المستخدم صلاحية عليها
                (item.perms && perms.find(row => {//من اجل النوافذ المساعدة او اي نافذة ممكن تفتح باكثر من صلاحية
                    return item.perms.split(',').includes(row.PermName + '') && row[item.permKind ? item.permKind : "Read"] == 1
                }))
            ) &&(item.perm !== 7202 || options.IsExternalRemitt))
            return item;
    });
};

export default function appDataReducer(appData = initialState, action) : any {

    switch (action.type) {
        case "ADD_DATA":
            let returnObj = { ...appData };
            returnObj[action.dataName] = action.payload;
            return returnObj;
        case "LOGIN":
            var routs = getAvailableRouts(action.payload.Perms, action.payload.Options);
            return {
                ...appData,
                options: action.payload.Options,
                perms: action.payload.Perms,
                routs
            };
        case "RELOAD":
            var perms = action.payload.IsLoged ? action.payload.User.Perms : [];
            var routs = getAvailableRouts(perms, action.payload.IsLoged ? action.payload.User.Options : action.payload.Options);
            let LangKey = localStorage.getItem("LangKey");
            if (LangKey === null)
                localStorage.setItem("LangKey", JSON.stringify(action.payload.keyValues));
            return {
                ...appData,
                LangKey: LangKey === null ? action.payload.keyValues : JSON.parse(LangKey),
                options: action.payload.IsLoged ? action.payload.User.Options : action.payload.Options,
                perms,
                routs
            };
        default:
            return appData
    }
};
