import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import * as app from "../../services/AppService";
import ColorMode from "../ColorMode";
import Language from "../Language";
import "./NavMenu.css";

export default function NavMenu(props) {
    let navigate = useNavigate();
    var Options = app.options();

    return (

        <Navbar className="shadow-sm public-layout" bg="primary-subtle" variant="dark" expand="lg">
            <Container>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                <div id="basic-navbar-nav" className="row w-100 mb-2 mt-2 ">

                    <div className="col-lg-8 col-8 fw-bold">
                        <Nav className="d-flex flex-row p-0 gap-0" variant="underline" defaultActiveKey={window.location.pathname} >
                            <ColorMode />
                            <Language />
                            <Nav.Item className="mx-3 align-self-center">
                                <Nav.Link as={Link} to="/" href="/">
                                    {app.translate("Main")}
                                </Nav.Link>
                            </Nav.Item>

                            {Options.Version_Kind != 7 && Options.Version_Kind != -1 && Options.HideButtonsInMainInterface == 0 && <Nav.Item className="mx-3 align-self-center">
                                <Nav.Link className="" as={Link} to="/TrackTransfer" href="/TrackTransfer">
                                    {app.translate("TrackTransfer")}
                                </Nav.Link>
                            </Nav.Item>}

                            {Options.Version_Kind != 7 && Options.Version_Kind != -1 && Options.HideButtonsInMainInterface == 0 && <Nav.Item className="mx-3 align-self-center">
                                <Nav.Link className="" as={Link} to="/OurAgents" href="/OurAgents">
                                    {app.translate("AllAgents")}
                                </Nav.Link>
                            </Nav.Item>}

                        </Nav>
                    </div>


                    <div className="col-lg-4 col-4 d-flex justify-content-end">
                        <Button Class="btn-light py-2 px-4 shadow-sm " Clicked={() => navigate('/Signin')} Label="LogIn" />
                    </div>

                </div>
            </Container>

        </Navbar >




    );
}
