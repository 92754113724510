import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import * as app from "../../services/AppService";
import * as http from "../../services/http";
import { logout } from "../../store/auth";
import ColorMode from "../ColorMode";
import Language from "../Language";

export default function Index(props) {
  function CheckLogin() {
    http.get(
      "/Login/CheckLogin",
      {},
      function (res) {
        if (!res.Success) {
          window.location.href = "/";
        } else {
          if (res.AdditionalData < 1)
            app.showAlert(
              app.translate("MsgSessionAfterSeconds"),
              "Warning",
              () => {
                window.location.href = "/";
              },
              () => http.get("/Login/SetLang", { lang: app.translate("Lang") })
            );
          setControl((old) => {
            if (res.CarryOnData) {
              var audio = new Audio("NewMessage.mp3");
              audio.play();
              if (Notification.permission === "granted") {
                var notification = new Notification(
                  app.translate("MessageSystem"),
                  {
                    icon: window.location.origin + "/Images/logo.png",
                    body: app.translate("YouhaveNewMessages"),
                  }
                );
                notification.onclick = function () {
                  window.open(window.location.origin + "/AgentMessageSystem");
                };
              }
            }
            return { ...old, msgCount: +res.Code };
          });
        }
      },
      true
    );
  }

  useEffect(() => {
    CheckLogin();
    var handler = setInterval(function () {
      CheckLogin();
    }, 10000);
  }, []);

  const optionInfo = useSelector((store) => store.data.options);
  const AgentBalance = useSelector((store) => store.data.AgentBalance);
  const dispatch = useDispatch();

  const [control, setControl] = useState({
    AllBalances: false,
    Prices: [],
    showBalance: app.getPermission(2103),
    msgCount: 0,
  });

  let agentIsAuthorize = app.getPermission(2103);

  function Logout() {
    http.post("/Login/Logout", {}, (res) => {
      dispatch(logout());
      window.open("/", "_self");
    });
  }

  //useEffect(() => {
  //        return app.ConnectSocket(setControl);
  //}, []);

  useEffect(() => {
    if (
      optionInfo.EnableLivePrices &&
      control.Prices.length == 0 &&
      !window.location.href.includes("Credit/BetweenCurrencies")
    )
      return app.ConnectSocket(setControl, true);
  }, [window.location.href]);

  useEffect(() => {
    app.RefreshAgentBalance();
  }, []);

  return (
    <>
      <Navbar
        bg="body-tertiary"
        expand="lg"
        className="sticky-top shadow-sm text-light-emphasis agent-top-header"
      >
        <Container fluid>
          <button
            className="btn bg-body me-3 shadow-sm border-secondary border-1 text-secondary rounded-1"
            onClick={props.menuButtonClicked}
            type="button"
          >
            <span className="iconssc-menu "></span>
          </button>

          <Navbar.Brand className="bg-transparent shadow-none">
            <img
              src={
                optionInfo.companyLogo.length === 0
                  ? "images/logo.png"
                  : "data:image/jpeg;base64," + optionInfo.companyLogo
              }
              height="35"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll" >
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  {AgentBalance.list.map((bal, idx) => (
                    <span className="row m-2 " key={idx}>
                      {" "}
                      {bal.Key} {bal.Value}
                    </span>
                  ))}
                </Tooltip>
              }
            >
              <div className="d-inline-flex align-items-center text-dark-emphasis position-relative">
                <Nav.Link>{app.translate("BalanceAdjust")} :</Nav.Link>
                {
                  <Nav.Link className="fw-lighter mx-2 ">
                    {control.showBalance && optionInfo.IsCentralBank == 0
                      ? AgentBalance.balanceAccount
                      : "*".repeat(
                        AgentBalance.balanceAccount.length > 3
                          ? AgentBalance.balanceAccount.length
                          : 3
                      )}
                  </Nav.Link>
                }
              </div>
            </OverlayTrigger>

            <div className="d-inline-flex align-items-center">
              <Button
                Class="outline-primary text-primary-emphasis"
                Icon={`${control.showBalance || !agentIsAuthorize ? "eye-hide" : "eye"
                  }`}
                Clicked={() =>
                  agentIsAuthorize &&
                  setControl((old) => ({
                    ...old,
                    showBalance: !old.showBalance,
                  }))
                }
              />
              <Nav.Link className="mx-2 d-none d-md-block text-dark-emphasis">
                {app.translate("GeneralNews")}
              </Nav.Link>
            </div>

            <div
              style={{ direction: "ltr" }}
              scrollamount="07"
              className="flex-fill w-50 d-none d-md-block text-dark-emphasis"
            >
              <marquee
                direction={app.translate("Lang") !== "ar" ? "left" : "right"}
              >
                {app.options().TapeNews}
              </marquee>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                Class="outline-primary text-primary-emphasis position-relative ms-3 "
                Icon="mail"
                Tooltip="FrmCorrespondences"
                Clicked={() => window.open("/AgentMessageSystem")}
              >
                <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                  {control.msgCount.toString()}
                </span>
              </Button>

              <Language />
              <ColorMode />
              <Button
                Class="outline-primary  text-primary-emphasis"
                Icon="off fw-bold"
                Tooltip="Signout"
                Clicked={Logout}
              />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {optionInfo.EnableLivePrices && (
        <Navbar
          bg="light-subtle"
          expand="xs"
          className="p-0 border text-dark-emphasis live-currency-bar"
        >
          <div
            style={{ direction: "ltr", fontSize: "11px", height: "25px" }}
            className="flex-fill d-none d-md-block pt-1 gap-0"
          >
            <marquee
              scrollamount="3"
              direction={app.translate("Lang") !== "ar" ? "left" : "right"}
            >
              {control.Prices.map((r, i) => (
                <div className=" d-inline-flex" key={i}>
                  <div className="vr mx-2"></div>
                  <span className="fw-bold align-self-center text-info">
                    {r.CurrencyView}
                  </span>
                  <div
                    style={{ width: "85px" }}
                    className={
                      "text-center bg-opacity-25 mx-1 " +
                      (r.OldBuy == 0
                        ? ""
                        : r.Buy > r.OldBuy
                          ? "bg-success"
                          : "bg-danger")
                    }
                  >
                    {r.Buy}
                    <span
                      className={
                        "me-1 iconssc-" +
                        (r.OldBuy == 0
                          ? ""
                          : r.Buy > r.OldBuy
                            ? "up-arrow text-success"
                            : "down-arrow text-danger")
                      }
                    >
                      {" "}
                    </span>
                  </div>
                  <div
                    style={{ width: "85px" }}
                    className={
                      "text-center bg-opacity-25 " +
                      (r.OldSale == 0
                        ? ""
                        : r.Sale > r.OldSale
                          ? "bg-danger"
                          : "bg-success")
                    }
                  >
                    {r.Sale}
                    <span
                      className={
                        "me-1 iconssc-" +
                        (r.OldSale == 0
                          ? ""
                          : r.Sale > r.OldSale
                            ? "down-arrow text-danger"
                            : "up-arrow text-success")
                      }
                    >
                      {" "}
                    </span>
                  </div>
                </div>
              ))}
            </marquee>
          </div>
        </Navbar>
      )}
    </>
  );
}
