import React, { ChangeEvent } from 'react';
import * as app from "../services/AppService";
import { RowItem } from '../models/row-item';
interface TextareaProps extends RowItem{
    OnChange: (text: string,) => void,
    OnBlur?:()=>void,
    Rows: number,
    Cols?: number,
    ID?: string,
    Label?: string,
    Key?: string,
    Hoover?: boolean,
    IsTable?: boolean,
    TableClass?: string,
    Disabled?: boolean,
    Model:string,
    Placeholder?:string,
    Symbol?: string,
    WithLabel?: boolean,
    LabelClass?: string,
    ContainerClass?: string,
    Class?: string,
}
function Textarea(props: TextareaProps) {

    function handler(event: ChangeEvent<HTMLTextAreaElement>) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value);
    }

    let textarea = <textarea rows={props.Rows} cols={props.Cols}
        id={props.ID ? props.ID : "txt" + props.Label}
        key={props.Key}
        data-bs-trigger={props.Hoover ? "hover" : ""}
        data-bs-toggle={props.Hoover ? "popover" : ""}
        data-bs-content={props.Hoover ? props.Hoover : ""}
        className={`form-control form-control-sm border rounded  ${props.IsTable ? " border-1  text-center " + props.TableClass : props.Class}`}
        disabled={props.Disabled}
        onChange={handler} value={props.Model}
        onBlur={props.OnBlur}
        placeholder={app.translate(props.Placeholder) + props.Symbol}
    />

    return (props.IsTable ? textarea :
        <div className="mt-2">
            {props.WithLabel && <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>}
            <div className={`input-group border rounded  ${props.ContainerClass}`}>
                {textarea}
            </div>
        </div>
    );
}
Textarea.defaultProps = {
    Class: " border-0 ",
    LabelClass: "text-primary-emphasis",
    TableClass: "",
    Type: "text",
    Placeholder: "",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    Symbol: "",
    WithLabel: true,
}
export default Textarea;