import React, { ChangeEvent } from "react";
import { translate } from "../services/AppService";


interface Props { 
    Model: string,
    Class?: string,
    Label?: string,
    placeholder?: string,
    Key?:string,
    ID?:string,
    OnChange?:(...args:any) => void ,
    OnEnterClicked?:(...args:any) => void ,
}

export default function Password(props:Props) {
    const [controle, setControle] = React.useState({
        hiddeStat: true
    });

    function handleChange(event:ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }

    function SetEyeStat() {
        setControle((old) => {
            return {
                hiddeStat: !old.hiddeStat,
            };
        });
    }

    function hanldeKeydown(evt:React.KeyboardEvent<HTMLInputElement>) {
        if (evt.key === 'Enter' && props.OnEnterClicked && typeof (props.OnEnterClicked) == "function")
            props.OnEnterClicked();
    }
    return (
        <div className="mt-2">
            <label className="form-label fw-semibold text-primary-emphasis">
                {translate(props.Label)}
            </label>

            <div className="input-group input-group-sm border rounded">
                <input
                    className={`form-control form-control-sm rounded ` + props.Class}
                    type={controle.hiddeStat ? "password" : "text"}
                    id={props.ID ? props.ID : "pass" + props.Label}
                    onChange={handleChange}
                    value={props.Model}
                    onKeyDown={hanldeKeydown}
                    placeholder={translate(props.placeholder)}
                />

                <button className="btn btn-sm rounded p-0 px-2" type="button" onClick={SetEyeStat}>
                    {controle.hiddeStat ? (
                        <i className="iconssc-eye fs-5 text-primary-emphasis"></i>
                    ) : (
                        <i className="iconssc-eye-hide fs-5 text-primary-emphasis"></i>
                    )}
                </button>
            </div>
        </div>
    );
}


Password.defaultProps = {
    Class: " border-0",
    Label: "Password",
    placeholder: "password",
};