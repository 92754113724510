import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import * as app from "../../services/AppService";
import AgentHeader from "./AgentHeader";
import AgentNavBar from "./AgentNavBar";
var DisplayDialog = () => <></>;

export default function AgentLayout(props) {
  const agentRouts = useSelector((store) => store.data.routs);
  const options = useSelector((store) => store.data.options);
  const appOptions = app.options();

  const [mobileMode, setMobileMode] = useState(window.innerWidth <= 768);
  const [model, setModel] = useState({
    menu: [],
    parentName: "",
    Level: 0,
    navigation: [],
    ActiveIndex: -1,
  });

  const [control, setControl] = useState({
    showAgentInfo: true,
    isMenuOpen: !mobileMode,
    DisplayAsDialog: false,
    menuData: [],
  });

  function checkWidth() {
    const w = window.innerWidth;
    if (w <= 768) {
      setMobileMode(true);
      setControl((old) => ({ ...old, isMenuOpen: false }));
    } else {
      setMobileMode(false);
    }
  }

  useEffect(() => {
    var menuData = agentRouts
      .map((r) => r.parentId)
      .filter(
        (item, index, array) =>
          item && item != 7 && item != 3 && array.indexOf(item) == index
      )
      .map((ID) => ({
        ID,
        name:
          ID == 1
            ? "Balances"
            : ID == 2
            ? "Remittances"
            : ID == 4
            ? "Credits"
            : ID == 5
            ? "CustomerManag"
            : ID == 6
            ? "CurrExchange"
            : ID == 8
            ? "RequestsAndOrders"
            : ID == 9
            ? "FrmAuctionDeliver"
            : "",
      }));
    if (
      menuData.find((r) => r.ID == 2) == undefined &&
      agentRouts.find((r) => r.parentId == 3)
    ) {
      menuData.push({ ID: 2, name: "Remittances" });
    }
    setControl((old) => ({
      ...old,
      menuData,
    }));
    setModel((old) => ({ ...old, menu: menuData }));
  }, []);

  const navigate = useNavigate();

  var path = useLocation().pathname;

  function openUrl(url) {
    if (
      url == "/Remitt/RemRec" &&
      options.RemOption == 1 &&
      options.IsCentralBank == 0
    ) {
      url = "Remitt/RemittRec";
    } else {
      // url = "Remitt/RemRec";
    }
    hideMenu();
    navigate(url);
  }

  async function UpdateMenu(item, goBack = false) {
    //تحديدشجرة الأباء للنافذة المفتوحة
    let tempNav = [];
    if (goBack) {
      tempNav = model.navigation.splice(
        0,
        model.navigation.findIndex((r) => r === item.name) + 1
      );
    } else if (item.ID == 7) tempNav = ["HelpWindows"];
    else {
      //في حال كانت الصفحة الجديدة هي اول صفحة نفتحها من هذه القائمة نضيف اسمها مباشرة للناف و الا نحذف اسم اخر صفحة مفتوحة و نضيف الجديدة
      tempNav =
        model.parentName === model.navigation[model.navigation.length - 1]
          ? [...model.navigation, item.name]
          : [
              ...model.navigation.splice(0, model.navigation.length - 1),
              item.name,
            ];
    }
    //تحديد القائمة التي ستظهر أو النافذة التي سننتقل اليها
    var sons = agentRouts.filter((r) => r.parentId && r.parentId == item.ID);
    //اظهار قائمة
    if (sons.length > 1 || item.ID == 2) {
      if (item.ID == 2) sons.push({ ID: 3, name: "Queries" });
      tempNav.push(sons[0].name);
      setModel((old) => ({
        ...old,
        parentName: item.name,
        menu: sons,
        navigation: tempNav,
        Level: tempNav.length,
        ActiveIndex: 0,
      }));
      if (sons[0].path)
        openUrl(
          sons[0].path + (sons[0].ulrParam ? "/" + sons[0].ulrParam : "")
        );
    }
    //الانتقال الى نافذة جديدة
    else {
      let element = sons && sons.length == 1 ? sons[0] : item;
      if (element.path) {
        setModel((old) => ({
          ...old,
          navigation: tempNav,
          ActiveIndex: model.menu.findIndex((x) => x.name === element.name),
        }));
        openUrl(
          element.path + (element.ulrParam ? "/" + element.ulrParam : "")
        );
      } else {
        await import(`../../pages/Agent/${element.element}`).then((module) => {
          DisplayDialog = module.default;
          setControl((old) => ({ ...old, DisplayAsDialog: true }));
          setModel((old) => ({
            ...old,
            navigation: tempNav,
            ActiveIndex: model.menu.findIndex((x) => x.name === element.name),
          }));
        });
      }
    }
  }

  function DisplayDialogClose() {
    setControl((old) => ({ ...old, DisplayAsDialog: false }));
    var pth = agentRouts.find((r) => r.path === path);
    if (pth === undefined) return;
    setModel((old) => ({
      ...old,
      ActiveIndex: old.menu.findIndex((x) => x.name === pth.name),
      navigation: [
        ...old.navigation.splice(0, old.navigation.length - 1),
        pth.name,
      ],
    }));
  }
  //حالة فتح نافذة ما من خلال تغيير الباث في المتصفح
  useEffect(() => {
    var pth = agentRouts.find((r) => r.path === path);

    if (pth === undefined) return;

    //في حال كانت النافذة المفتوحة موجودةو ضمن القائمة الحالية المعروضة فلانحدث القائمة
    if (model.menu.find((r) => pth.name === r.name) !== undefined) return;

    let item =
      pth.parentId == 7
        ? { ID: 7, name: "HelpWindows" }
        : path.parentId == 3
        ? { ID: 3, name: "Queries" }
        : control.menuData.find((r) => r.ID === path.parentId);

    if (item == undefined) return;

    UpdateMenu(item);
  }, [path]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", checkWidth);

    return () => window.removeEventListener("resize", checkWidth);
  }, []);

  function hideMenu() {
    if (mobileMode) {
      setControl((old) => ({ ...old, isMenuOpen: false }));
    }
  }

  return (
    <div style={{ height: "calc(100vh - 88px)" }} className="bg-body fade-in agent-layout">
      <AgentHeader
        menuButtonClicked={() =>
          setControl((old) => ({ ...old, isMenuOpen: !old.isMenuOpen }))
        }
      />
      <div
        className={`bd-sidebar bg-body sidebar ${
          control.isMenuOpen ? "show" : "hide"
        } ${appOptions.EnableLivePrices ? "enable-live-prices" : ""}`}
      >
        <AgentNavBar
          Menu={model.menu}
          Parentname={model.parentName}
          Level={model.Level}
          ActiveIndex={model.ActiveIndex}
          ButtonClicked={(item) => {
            UpdateMenu(item);
            hideMenu();
          }}
          Back={() => {
            if (model.parentName !== "Queries") {
              setModel({
                menu: control.menuData,
                parentName: "",
                Level: 0,
                navigation: [],
              });
              openUrl("/AgentDashboard");
              return;
            }

            UpdateMenu({ ID: 2, name: "Remittances" }, true);
          }}
          HomeEvent={() => {
            setModel({
              menu: control.menuData,
              parentName: "",
              Level: 0,
              navigation: [],
            });
            openUrl("/AgentDashboard");
          }}
          ShowAgentInfo={control.showAgentInfo}
          AgentInfoClicked={() =>
            setControl((old) => ({
              ...old,
              showAgentInfo: !old.showAgentInfo,
            }))
          }
        />
      </div>
      <main
        className={`d-flex flex-xl-nowrap w-100 overflow-auto bg-body ${
          control.isMenuOpen ? "menu-open" : ""
        }`}
      >
        <div className="card bg-body border-0 container-fluid mt-5">
          <div className=" pt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                {model.navigation.map((nav, index) => (
                  <li
                    className="h5 text-primary-emphasis fw-bold breadcrumb-item"
                    key={index}
                  >
                    {app.translate(nav)}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          <div>
            {control.DisplayAsDialog ? (
              <DisplayDialog OnClose={DisplayDialogClose} />
            ) : (
              props.children
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
