import React, { ChangeEvent } from "react";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";
interface SwitchesProps extends RowItem{
    OnChange: (res: boolean) => void;
    ID: string
    Label: string
    Class: string
    Name: string
    Checked: boolean
    Disabled:boolean
}
function Switches(props: SwitchesProps) {
    function handler(event: ChangeEvent<HTMLInputElement>) {
        const { checked } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(checked);
    }
    return (
        <div className={`form-check form-switch mx-2 mt-1 ${props.Class}`}>
            <input
                id={props.ID ? props.ID : "swch" + props.Label}
                type="checkbox"
                checked={props.Checked}
                name={props.Name}
                disabled={props.Disabled}
                className="form-check-input"
                onChange={handler}
            />
            <label
                htmlFor={props.Name}
                className={"ms-2 form-check-label text-body "}
            >
                {app.translate(props.Label)}
            </label>
        </div>
    );
}
Switches.defaultProps = {
    Class: "",
    Name: "",
    Label: "",
    Disabled: false,
};
export default Switches;
