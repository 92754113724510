import * as React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import * as app from "../../services/AppService";
import * as http from "../../services/http.js";
import { logout } from "../../store/auth";
import ColorMode from "../ColorMode";
import Language from "../Language";

export default function UserHeader(props) {
  const optionInfo = useSelector((store) => store.data.options);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  function Logout() {
    http.post("/Login/Logout", {}, (res) => {
      dispatch(logout());
      window.open("/", "_self");
    });
  }

  function openMessages() {
    navigate("/MessageSystem");
  }

  return (
    <>
      <Navbar
        bg="body-tertiary"
        expand="lg"
        className="sticky-top shadow-sm border-bottom"
      >
        <Container fluid>
          {/*for main menue*/}
          <button
            className="btn btn-light me-3 shadow-sm border-secondary border-1 text-secondary rounded-1"
            onClick={props.menuButtonClicked}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <span className="iconssc-menu "></span>
          </button>

          <div
            style={{ direction: "ltr" }}
            scrollamount="7"
            className="w-50 d-none d-sm-block d-lg-none text-dark-emphasis"
          >
            <marquee
              direction={app.translate("Lang") !== "ar" ? "left" : "right"}
              className="w-100"
            >
              {app.options().TapeNews}
            </marquee>
          </div>

          <Navbar.Brand className="bg-transparent shadow-none">
            <img
              src={
                optionInfo.companyLogo.length === 0
                  ? "images/logo.png"
                  : "data:image/jpeg;base64," + optionInfo.companyLogo
              }
              height="35"
              alt="Logo"
            />
          </Navbar.Brand>

          <button
            className="btn btn-primary  shadow-sm border-secondary border-1 text-secondary rounded-1 d-block d-lg-none"
            onClick={props.profileButtonClicked}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <span className="iconssc-user "></span>
          </button>

          {/*<Navbar.Toggle aria-controls="navbarScroll" />*/}

          <Navbar id="navbarScroll" className="w-100 d-none d-lg-block">
            <div className="d-flex ">
              <div className="vr mx-3 d-none d-lg-block"></div>
              <Nav
                className=" my-2 my-lg-0 d-none d-lg-block"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Form className="d-flex ">
                  <Button
                    Class="border-0 text-primary-emphasis"
                    Disabled={true}
                    Icon="search"
                  ></Button>
                  <Form.Control
                    type="search"
                    placeholder={app.translate("SearchHere")}
                    className="me-2 border-0 bg-light-subtle"
                    aria-label="Search"
                  />
                </Form>
              </Nav>

              <div
                style={{ direction: "ltr" }}
                scrollamount="7"
                className="w-100 d-none d-lg-block text-dark-emphasis"
              >
                <marquee
                  direction={app.translate("Lang") !== "ar" ? "left" : "right"}
                  className="w-100"
                >
                  {app.options().TapeNews}
                </marquee>
              </div>

              {/*<Nav.Item className="d-flex w-25 mt-1">*/}
              {/*    <Nav.Link eventKey="link-1 " className="text-dark-emphasis" disabled><span className="text-primary-emphasis mx-2 iconssc-user"></span></Nav.Link>*/}
              {/*    <Nav.Link eventKey="link-1 " className="text-dark-emphasis" disabled><small>{app.options().userName}</small></Nav.Link>*/}
              {/*</Nav.Item>*/}

              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    <span className="d-none d-xl-block">
                      {app.options().userName.split("-").slice(1).join("-")}
                    </span>
                    <span className="d-none d-lg-block d-xl-none">
                      {app.options().userName}
                    </span>
                  </Tooltip>
                }
              >
                <Nav.Item className="d-flex w-10 flex-nowrap mt-1">
                  <Nav.Link
                    eventKey="link-1 "
                    className="text-dark-emphasis"
                    disabled
                  >
                    <span className="text-primary-emphasis mx-2 iconssc-user"></span>
                  </Nav.Link>
                  <Nav.Link
                    eventKey="link-1 "
                    className="text-dark-emphasis d-none d-xl-block"
                    disabled
                  >
                    <small>{app.options().userName.split("-", 1)}</small>
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>

              <Button
                Class="text-primary-emphasis position-relative ms-3"
                Icon="mail"
                Tooltip="FrmCorrespondences"
                Clicked={openMessages}
              >
                <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                  {props.msgCount.toString()}
                </span>
              </Button>

              <Language Class="mt-1 fs-5" />
              <ColorMode />

              <Button
                Class="outline-primary  text-primary-emphasis"
                Tooltip="Signout"
                Clicked={Logout}
                Icon="off fw-bold"
              ></Button>
            </div>
          </Navbar>
        </Container>
      </Navbar>
    </>
  );
}
