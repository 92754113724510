import React, { useEffect, useState } from "react";
import { MultiSelect as MultiSelected } from "react-multi-select-component";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";

interface Props extends RowItem {
  Label?: string;
  LabelClass: string;
  Class: string;
  Disabled: boolean;
  value: any;
  options: any[];
    onChange: (...args: any) => void
    NoLabel?: boolean
}

export default function MultiSelect(props: Props) {
    const [selectedObj, setselectedObj] = useState<any[]>([]);
    const [options, setOptions] = useState<any[]>([]);

    const SelectValues = () => {
        var ArrV = [];
        ArrV = options.filter((item) => props.value && props.value.includes(item.value));
        setselectedObj(ArrV);
    }

    useEffect(() => {
        SelectValues();
    }, [props.value, options])

    const SelectOption = () => {
        var arr = [];
        arr = props.options.map((item) => {
            return { label: item.Value, value: item.Key };
        });
        setOptions(arr);
    }

    useEffect(() => {
        SelectOption();
    }, [props.options]);

    const handler = (selectedObjectsArray: any[]) => {
        props.onChange(selectedObjectsArray, selectedObjectsArray.map((i) => i.value));
    };

    var LablesValues = {
        selectAll: app.translate("SelectAll"),
        search: app.translate("Search"),
        selectSomeItems: app.translate("select"),
        allItemsAreSelected: app.translate("Don") + " " + app.translate("SelectAll")
    };

    return (

        <div className="mt-1">
            {!props.NoLabel &&<label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>}
            <MultiSelected
                options={options}
                value={selectedObj}
                onChange={handler}
                labelledBy="Selectmax"
                className={`shadow-sm rounded h-25 ${props.Class}`}
                disabled={props.Disabled}
                overrideStrings={LablesValues}
            />
        </div>
    );
};

MultiSelect.defaultProps = {
    Class: " ",
    LabelClass: "fw-semibold text-primary-emphasis ",
    Disabled: false,
}