export function hideArchiveDialog() {
    return {
        type: "HIDE_Archive_DIALOG"
    }
}

export function showArchiveDialog(model,onOk) {

    return {
        type: "SHOW_Archive_DIALOG",
        diaolgData: {
            model,
            show: true,
            onOk
        }
    }
}

const initialState = {
    model: {
        ID: '0',
        Reason_Kind: '0',
        Parent_ID: '0',
        Date: '',
        Reason: '',
        Note: '',
    },
    show: false,
    onOk: null
}

export default function msgDialogReducer(archiveDialog = initialState, action) {
    switch (action.type) {
        case "SHOW_Archive_DIALOG":
            return action.diaolgData
        case "HIDE_Archive_DIALOG":
            return { ...initialState, Show: false }
        default:
            return archiveDialog
    }
}
