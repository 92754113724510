import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { RowItem } from "../models/row-item";
import * as app from "../services/AppService";
interface CurrencyInputProps extends RowItem {
  ID?: string;
  OnChange?: (val: number) => void;
  Model: string | number;
  Disabled?: boolean;
  Class?: string;
  Label?: string;
  Key?: string;
  IsTable?: boolean;
  CurrencyID?: string | number;
  OnBlur?: () => void;
  LabelClass?: String;
  ContainerClass?: String;
  Icon?: String;
  TableClass?: String;
}
function CurrencyInput(Props: CurrencyInputProps) {
  const [model, setModel] = useState({
    elmntVal: "0",
    isfocused: false,
  });

  useEffect(() => {
    if (!model.isfocused)
      setModel((old) => ({
        ...old,
        elmntVal: old.isfocused
          ? format(Props.Model)
          : app.roundNumber(Props.Model, Props.CurrencyID, true),
      }));
  }, [Props.Model, Props.CurrencyID, model.isfocused]);

  function handler(event: { target: { value: string } }) {
    const { value } = event.target
    if (Props.OnChange && typeof Props.OnChange == "function") {
      Props.OnChange(+(value + "").replace(/,/g, ""));
    }
    let elmntVal = format(value);
    setModel((old) => ({
      ...old,
      elmntVal,
    }));
  }

  function format(value: string | number) {
    var arr = value.toString().split(".");
    return arr && arr.length > 1 && arr[1] === ""
      ? (+arr[0].replace(/,/g, "") || 0).toLocaleString("en-US") + "."
      : (+arr[0].replace(/,/g, "") || 0).toLocaleString("en-US") +
          (arr.length < 2 ? "" : "." + arr[1]);
  }

  function hanldeKeydown(evt: { keyCode: number; preventDefault: Function }) {
    if (
      ![
        96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 8, 39, 37, 46, 48,
        49, 50, 51, 52, 53, 54, 55, 56, 57, 9, 65, 190, 17, 86,
      ].includes(evt.keyCode)
    )
      evt.preventDefault();
  }

  function onBlur() {
    setModel({
      isfocused: false,
      elmntVal: app.roundNumber(model.elmntVal, Props.CurrencyID, false),
    });
    if (Props.OnBlur && typeof Props.OnBlur == "function") Props.OnBlur();
  }

  const handleFocus = (event: { target: { select: Function } }) => {
    event.target.select();
    setModel((old) => ({ ...old, isfocused: true }));
  };

  let input = (
    <input
      type="text"
      id={Props.ID ? Props.ID : "cur" + Props.Label}
      key={Props.Key}
      className={`form-control form-control-sm border rounded ${
        Props.IsTable
          ? " border-1  text-center " + Props.TableClass
          : Props.Class
      }`}
      disabled={Props.Disabled}
      onChange={handler}
      onKeyDown={hanldeKeydown}
      value={model.elmntVal}
      onFocus={handleFocus}
      onBlur={onBlur}
    />
  );

  return Props.IsTable ? (
    input
  ) : (
    <div className="mt-1">
      <label className={`fw-bold  ${Props.LabelClass}`}>
        {app.translate(Props.Label)}
      </label>
      <div className={`input-group rounded  ${Props.ContainerClass}`}>
        {input}
        {Props.Icon && (
          <button className="btn btn-light" type="button" disabled>
            <i className={"text-primary-emphasis icofont-" + Props.Icon} />
          </button>
        )}
      </div>
    </div>
  );
}

CurrencyInput.propTypes = {
  Label: PropTypes.string,
  Class: PropTypes.string,
  Disabled: PropTypes.bool,
  Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  OnChange: PropTypes.func,
  Key: PropTypes.string,
  IsTable: PropTypes.bool,
  OnBlur: PropTypes.func,
  CurrencyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
CurrencyInput.defaultProps = {
  Class: " ",
  LabelClass: "text-primary-emphasis",
  ContainerClass: " ",
  Disabled: false,
  Key: "input",
  IsTable: false,
  CurrencyID: 1,
};
export default CurrencyInput;
