/* eslint-disable react-hooks/exhaustive-deps */
import * as app from "../../services/AppService"
import * as http from "../../services/http"
import { Select, Input, DatePicker, Textarea } from "../../components";
import React, { useState, useEffect } from "react" 
import Dialog from "./Dialog";
import { ResultVM } from "../../models/result-vm";

interface Model {
    ID: string;
    Reason_Kind: string;
    Parent_ID: string;
    Date: string;
    Reason: string;
    Note: string;
}
interface ArchiveDialogProps {
    model: Model
    show: boolean
    onOk:()=>void
}

 function ArchiveDialog(props: ArchiveDialogProps) {

    const initModel = {
        ID: "0",
        Reason_Kind: "",
        Parent_ID: "",
        Date: app.today(),
        Reason: "",
        Note: "",
    };

    const [model, setModel] = useState<Model>(initModel);
     
    function save() {
        http.post("/ClientsManagement/SaveArchive", model, (res: ResultVM) => {
            if (res.Success)
                app.showAlert(app.translate("MsgCompleteProcess"), app.translate("Success"),
                    () => {
                        props.onOk?.();
                        app.hideArchive()
                    });
            else
                app.disblayError(res);
        });
    }

    useEffect(() => {
        if (props.show)
            setModel(old => ({ ...old, ...props.model }));
        else
            setModel(initModel)
    }, [props.show])

     return (
         <Dialog {...props} Show={props.show} Title="Archiving" Size="md" OkLabel="Save" Ok={save} OnClose={app.hideArchive}>
            <DatePicker Model={model.Date}
                OnChange={Date => setModel(old => ({ ...old, Date }))} />

            <Input Model={model.Reason} Label="Notes"
                OnChange={Reason => setModel(old => ({ ...old, Reason }))} />

            <Select Model={model.Reason_Kind} Label="Reason"
                Options={app.GetKeyValueArr(["CreateData", "EditData", "PrintForm", "FrmRemittDoc", "CardPrintKYC",])}
                OnChange={Reason_Kind => setModel(old => ({ ...old, Reason_Kind }))} />

             <Textarea Rows={3} Model={model.Note} Label="Notice"
                OnChange={Note => setModel(old => ({ ...old, Note }))} />

        </Dialog>
    );
}
ArchiveDialog.defaultProps = {
    show:false
}
export default ArchiveDialog