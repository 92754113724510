import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "src/core/utils/is";
import { Button, Row } from "../../components";
import * as app from "../../services/AppService";

export default function Index(props) {
  const optionInfo = useSelector((store) => store.data.options);

  useEffect(() => {});
  return (
    <>
      <div
        className=" d-flex flex-column w-100 h-100"
        // style={
        //   optionInfo.EnableLivePrices ? { height: "calc(100vh - 88px)" } : {}
        // }
      >
        <div className="flex-grow-1 m-2 overflow-y-auto overflow-x-hidden z-10">
          <div className="">
            <>
              <Row>
                <Button
                  Icon="home"
                  IconSize="5"
                  Class={`w-100 bg-primary text-white py-1 d-flex justify-content-between align-items-center`}
                  Label="Home"
                  Clicked={() => props?.HomeEvent?.()}
                />
              </Row>

              {props.Level > 0 ? (
                <div className="mt-1 d-flex gap-1">
                  <Button
                    Icon={`${
                      !app.translate("Lang") === "ar"
                        ? "right-arrow-light"
                        : "left-arrow-light"
                    } fs-6 text-white`}
                    Clicked={props.Back}
                  />
                  <Button
                    Class={`w-100 h-100 bg-secondary text-white fw-bold`}
                    Label={props.Parentname}
                    Icon="left-arrow t-color"
                    Clicked={() => {}}
                  />
                </div>
              ) : (
                <></>
              )}

              {props.Menu.map((item, index) => (
                <div className={`mt-1`} key={index}>
                  <Button
                    Icon={`${
                      item.ID && item.ID != 1 && item.ID != 9 && item.ID != 6
                        ? !app.translate("Lang") === "ar"
                          ? "right-arrow"
                          : "left-arrow"
                        : "left-arrow t-color"
                    }`}
                    Class={`w-100 py-1 d-flex justify-content-between align-items-center  ${
                      props.ActiveIndex == index
                        ? "text-white btn-primary"
                        : "text-primary-emphasis border-1 border-primary"
                    }`}
                    Label={item.name}
                    Clicked={() => {
                      props.ButtonClicked(item);
                    }}
                  />
                </div>
              ))}
            </>
          </div>
        </div>

        <div className="m-2">
          <span
            className={` d-flex justify-content-center mb-2  border-bottom border-1 btn-arrow ${
              props.ShowAgentInfo
                ? "iconssc-down-arrow-2"
                : "iconssc-up-arrow-light "
            }`}
            onClick={props.AgentInfoClicked}
          ></span>
          {props.ShowAgentInfo && (
            <div>
              <h5 className="text-secondary-emphasis fw-bold">
                {app.translate("Welcome")}
              </h5>
              <h5 className="text-primary-emphasis fw-bold">
                {app.options().userName}
              </h5>
              <p className="text-dark-emphasis">
                <b>{app.translate("Branch")} :</b>{" "}
                {app.options().Authorized_person}
              </p>
            </div>
          )}
          <div
            className={`d-flex justify-content-center ${
              isMobile() && "info-window-btn"
            } `}
          >
            <Button
              Icon="settings"
              Class={`w-100 text-white py-1 d-flex justify-content-between align-items-center ${
                props.Parentname === "HelpWindows"
                  ? "btn-primary"
                  : "btn-secondary"
              }`}
              Disabled={props.Parentname === "HelpWindows"}
              Label="HelpWindows"
              Clicked={() => {
                props.ButtonClicked({ ID: 7, name: "HelpWindows" });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
