import * as app from "../../services/AppService"
import * as http from "../../services/http"
import { DatePicker, Input, Select } from "../../components";
import UserBranches from "../ui/UserBranches"
import React, { useEffect, useState } from "react"
import 'react-phone-input-2/lib/style.css'
import Dialog from "./Dialog";

function ChequeDialog(props) {

    let initModel = props.Model
    const [model, setModel] = useState(initModel);
    const [data, setData] = useState( {
        BanksAccounts: [],
        ChecksBooks: [],
        Checks:[]
    });
    useEffect(() => {
        setModel(initModel)
    }, [props.Model]);
    useEffect(() => {
        if (model.BankID == 0) return;
        http.get("/Operation/GetTotalBalance", { bankAccountId: model.BankID }, res => {
            setModel(old => ({
                ...old,
                BalanceAccount: res.AdditionalData.BalanceAccount,
                ChequeBankNonePay: res.AdditionalData.ChequeBankNonPay,
                TotalBalance: res.AdditionalData.Total,
            }))
        });
        http.get("/Operation/GetCheckBooks", { accId: model.BankID }, res => {
            setData(old => ({ ...old, ChecksBooks: res.AdditionalData }));
            if (res.AdditionalData.length > 0 && model.ChequeBookID == 0)
                setModel(old => ({
                    ...old,
                    ChequeBookID: res.AdditionalData[0].ID,
                }))
        })
    }, [model.BankID]);

    useEffect(() => {
        if (model.ChequeBookID == 0) return;
        http.get("/Operation/GetChecks", { checkBID: model.ChequeBookID, checkID: model.ChequeID }, res => {
            setData(old => ({ ...old, Checks: res.AdditionalData }));
            if (res.AdditionalData.length > 0 && model.ChequeID == 0) {
                setModel(old => ({
                    ...old,
                    ChequeID: res.AdditionalData[0].ID,
                    ChequeNumber: res.AdditionalData[0].CheckNum
                }));
            }
        });
    }, [model.ChequeID, model.ChequeBookID]);

    useEffect(() => {
        app.getBankAccounts(model.ChequeBranchID, props.Currency, (BanksAccounts) => {
            setData(old => ({ ...old, BanksAccounts }));
            if (model.BankID == 0 && BanksAccounts.length > 0)
                bankAccountsChanged(BanksAccounts[0].Key, BanksAccounts)
        });
    }, [model.ChequeBranchID]);
    function branchChanged(ChequeBranchID) {
        setModel(oldModel => ({ ...oldModel, ChequeBranchID, BankID: 0, ChequeBookID: 0, ChequeID :0}))
        setData(old => ({ ...old, ChecksBooks: [], Checks:[] }))
    }
    function bankAccountsChanged(BankID, BanksAccounts) {
        setModel(oldModel => ({
            ...oldModel,
            BankID,
            BankName: app.findObjectByKey(BanksAccounts, "Key", +BankID).Value,
            ChequeBookID: 0, ChequeID: 0
        }))
        setData(old => ({ ...old, ChecksBooks: [], Checks: [] }));
    }
    return (
        <Dialog Show={props.Show} Title={props.BankPayment ? "Payment" : "DataCheque"} Ok={() => props.OnDone(model)} Size="md">

            {(props.IsInCheck && !props.BankPayment) &&
                <div className="row">
                    <div className="col">
                        <Input Model={model.BankName} Label="DraweeBank"
                            OnChange={BankName => setModel(oldModel => ({ ...oldModel, BankName }))} />
                    </div>
                    <div className="col">
                        <Input Model={model.ChequeNumber} Label="NoCheck"
                            OnChange={ChequeNumber => setModel(oldModel => ({ ...oldModel, ChequeNumber }))} />
                    </div>
                </div>
            }
            {props.BankPayment &&
                <div>

                    <div className="row">
                        <div className="col">
                            <UserBranches Model={model.ChequeBranchID} OnChange={branchChanged}
                                OnLoad={(branches) => { if (model.ChequeBranchID == 0) setModel(old => ({ ...old, ChequeBranchID: branches[0].Key })) }} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Select Options={data.BanksAccounts} Model={model.BankID} Label="DraweeBank"
                                OnChange={bankAccountsChanged} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Input Model={model.ChequeNumber} Label="NotificatNo"
                                OnChange={ChequeNumber => setModel(oldModel => ({ ...oldModel, ChequeNumber }))} />
                        </div>
                    </div>
                </div>
            }
            {(!props.IsInCheck && !props.BankPayment) &&
                <div>

                    <div className="row">
                        <div className="col">
                            <UserBranches Model={model.ChequeBranchID} OnChange={branchChanged}
                                OnLoad={(branches) => { if (model.ChequeBranchID == 0) setModel(old => ({ ...old, ChequeBranchID: branches[0].Key })) }} />
                        </div>
                        <div className="col">
                            <Select Options={data.BanksAccounts} Model={model.BankID} Label="DraweeOnBank"
                                OnChange={bankAccountsChanged} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Select Options={data.ChecksBooks} Key="ID" Value="NumBook" Model={model.ChequeBookID} Label="ChecksBook"
                                OnChange={(ChequeBookID) => setModel(oldModel => ({ ...oldModel, ChequeBookID, ChequeID:0 }))} />
                        </div>
                        <div className="col">
                            <Select Options={data.Checks} Key="ID" Value="CheckNum" Model={model.ChequeID} Label="NoCheck"
                                OnChange={ChequeID => setModel(oldModel => ({
                                    ...oldModel,
                                    ChequeID,
                                    ChequeNumber: app.findObjectByKey(data.Checks, "ID", +ChequeID).CheckNum
                                }))} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Input Model={model.BalanceAccount} Label="Balance" Disabled={true} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Input Model={model.ChequeBankNonePay} Label="ChecksCollection" Disabled={true} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Input Model={model.TotalBalance} Label="TotalBalance" Disabled={true} />
                        </div>
                    </div>

                </div>
            }
            {!props.BankPayment
                && <div className="row">
                <div className="col">
                    <Input Model={model.ChequeBeneficiary} Label="Beneficiary"
                        OnChange={ChequeBeneficiary => setModel(oldModel => ({ ...oldModel, ChequeBeneficiary }))} />
                </div>
                <div className="col">
                    <Input Model={model.ChequeOwner} Label="DrawerName"
                        OnChange={ChequeOwner => setModel(oldModel => ({ ...oldModel, ChequeOwner }))} />
                </div>
            </div>}
            

            <div className="row">
                <div className="col">
                    <DatePicker Model={model.ChequeRelease} Label={!props.BankPayment?"DataIssued":"TranDate"}
                        OnChange={ChequeRelease => setModel(oldModel => ({ ...oldModel, ChequeRelease }))} />
                </div>
                <div className="col">
                    <DatePicker Model={model.ChequeMaturity} Label="DateMaturity"
                        OnChange={ChequeMaturity => setModel(oldModel => ({ ...oldModel, ChequeMaturity }))} />
                </div>
            </div>

        </Dialog>
    );
}

export default ChequeDialog;
