import React from 'react';
import './UserNavBar.css';
import * as app from "../../services/AppService";
import Dropdown from 'react-bootstrap/Dropdown';



export default function UserNavBar(props) {

    return (
        <div className="offcanvas offcanvas-start show sticky-xl-top " tabIndex="-1" id="bdSidebar" aria-labelledby="bdSidebarOffcanvasLabel">
            <div className="offcanvas-body ">
                {props.MenuArr.map((firstNode, index) =>
                    <Dropdown onClick={(evt) => props.FNclicked(firstNode, evt)} className="mb-3 mx-3 dropdown" key={index} autoClose={false}>
                        <Dropdown.Toggle variant={firstNode.isActive ? "primary" : "white"} className={`${firstNode.isActive ? "text-light-emphasis bg-primary-subtle  " : "text-dark-emphasis"} border-0 d-flex py-0 w-100 rounded-0 rounded-top `} id="dropdown-autoclose-outside">
                            <span className={`align-self-center mx-2 fs-4 ${firstNode.isActive ? "text-light-emphasis" : "text-primary-emphasis"} ${firstNode.icon}`}></span>
                            <span className="align-self-center me-auto fw-bold">{app.translate(firstNode.name)}</span>
                            <span className="align-self-center icofont-location-arrow"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100 border-0 position-sticky dropdown-menu-nav" key={index}>
                            {
                                firstNode.Child.map((secondNode, sindex) =>
                                    <Dropdown.Item key={sindex} onClick={() => props.SNclicked(secondNode)}>{app.translate(secondNode.name)}</Dropdown.Item>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
        </div>
    );
}