import React, { useRef } from "react";
import Checkbox from "./Checkbox";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";

interface Props extends RowItem{ 
    ID:string
    Label: string;
    Class: string;
    Disabled: boolean;
    Required: boolean;
    Placeholder: string;
    OnChange: (arg:string) => any;
    OnBlur?: () => any;
    CheckboxModel: boolean;
    IsTable: boolean;
    LabelClass?: string;
    CheckboxChanged: (...args:any) => any
    TableClass:string
    Model:any
}

function DatePicker(props:Partial<Props>) {
    const datePickerRef = useRef<HTMLInputElement>(null);
    //https://reactdatepicker.com/#example-custom-date-format
    // اذا بدنا نثبت الفورمات
    function getDateString(date:string) {
        try {
            let returnDate = new Date(date)
            let year :string | number= returnDate.getFullYear();
            year = year < 10 ? "000" + year :
                year < 100 ? "00" + year :
                    year < 1000 ? "0" + year : year;
            if (isNaN(returnDate.getMonth()) || isNaN(returnDate.getDate()))
                throw new Error("not valid number");
            return (
                year + "-" +
                (returnDate.getMonth() < 9 ? "0" : "") +
                (returnDate.getMonth() + 1) +
                "-" +
                (returnDate.getDate() < 10 ? "0" : "") +
                returnDate.getDate()
            );
        } catch (e) {
            return date;
        }
    }

    function handler(event:any) {
        const { value } = event.target;
            props?.OnChange?.(value);
    }

    function blurhandler(event:any) {
        const { value } = event.target;
        if (value === '')
            props?.OnChange?.(app.today());
        else
            props?.OnBlur?.();
            

    }
    var datePicker = <input
        id={props.ID ? props.ID : "date" + props.Label}
        ref={datePickerRef}
        type="date"
        value={getDateString(props.Model)}
        className={`form-control form-control-sm rounded ${props.Required && !props.Model ? " is-invalid  border-1" : props.IsTable ? " border-1 text-center bg-body-tertiary " + props.TableClass : "border-0 " + props.Class}`}
        disabled={props.Disabled || props.CheckboxModel === false}
        onChange={handler}
        onBlur={blurhandler}
        placeholder={app.translate(props.Placeholder)}
        max="3000-12-31"
        required={props.Required}
    />;

    function openPicker() {
        datePickerRef.current?.showPicker()
    }

    return (
        props.IsTable ? datePicker:
        <div className="mt-1">
                <label className={"fw-semibold text-primary-emphasis " + props.LabelClass}>{app.translate(props.Label)}</label>
            <div className="input-group border rounded">
                    {props.CheckboxChanged && <Checkbox Name="IsFrom" checkClass="mt-1 ms-2" className="text-center" Model={props.CheckboxModel!} OnChange={props.CheckboxChanged} />}
                    {datePicker}
                    <button className="btn btn-sm border-0 pt-1 px-2 btn-primary" type="button" onClick={openPicker} disabled={props.Disabled || props.CheckboxModel === false}>
                        <i className={` iconssc-calender`} />
                    </button>
            </div>
        </div>
    );
}

DatePicker.defaultProps = {
    Label: "Date",
    Model: new Date(),
    Placeholder: "",
    Class: " ",
    Disabled: false,
    Required: false,
    IsTable: false
}

export default DatePicker;