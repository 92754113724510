import React from "react";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";

interface StatisticDivProps extends RowItem {
    Label: string,
    ExtentionClass: string,
    Class: string
    LabelClass: string,
    ResultClass: string,
    Result: string,
    children: React.ReactElement[] | React.ReactElement
}
function StatisticDiv(props: Partial<StatisticDivProps>) {
    return (
        <div className={`rounded border border-1 ${props.Class! + props.ExtentionClass!}`}>
            <div className={`text-start mx-1 my-1 ${props.LabelClass}`}>
                {app.translate(props.Label)}
                <div className={`d-inline-block mx-2 fw-bold ${props.ResultClass}`}>{props.Result}</div>
            </div>
        </div>
    );
}

StatisticDiv.defaultProps = {
    Class: "col-12 col-lg-auto bg-body border-primary-subtle shadow-sm ",
    LabelClass: "text-primary-emphasis fw-normal",
    ResultClass: "text-success-emphasis ",
    ExtentionClass:""
};

export default StatisticDiv;
